import { Component, OnInit } from '@angular/core';
declare var showSlides: any;
import '../../../../assets/js/theme.js';


@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {
  constructor() { }

  ngOnInit() {
    showSlides(1)
  }
  currentYear = new Date().getFullYear();
}

