import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { AuthService } from "../../../core/auth/auth.service";
import { Event, NavigationEnd, Router, RouterEvent } from "@angular/router";
import { CampaignSuccessPromtComponent } from "../../components/campaign-success-promt/campaign-success-promt.component";
import { MatDialog } from "@angular/material/dialog";
import { LoaderDialogComponent } from "../../components/loader-dialog/loader-dialog.component";
import { MessagesData } from "../../../core/data/message-data";
import { CampaignService } from "../../../core/services/campaign/campaign.service";
import { QnaryService } from "../../../qnary/qnary.service";

@Component({
  selector: "app-postlogin-hearder",
  templateUrl: "./postlogin-hearder.component.html",
  styleUrls: ["./postlogin-hearder.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostloginHearderComponent implements OnInit {
  public loaderDialogRef: any;
  private role: string;
  private industry: any;
  constructor(
    private authService: AuthService,
    private qnaryservice: QnaryService,
    private router: Router,
    public dailog: MatDialog,
    private campaignService: CampaignService,
    private cdr: ChangeDetectorRef
  ) {
    router.events.subscribe((val: RouterEvent) => {
      // see also
      if (
        val instanceof NavigationEnd &&
        !Boolean(["/package", "/summary", "/campaign", "/payment"].includes(val.url))
      ) {

        sessionStorage.clear();
      }
    });
  }

  ngOnInit() {
    this.campaignService.hideHeader = false;
    this.getUserDetail();
  }
  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }
  getUserDetail() {
    const createCampaignSessionData = sessionStorage.getItem("createCampaignRequestData") && JSON.parse(sessionStorage.getItem("createCampaignRequestData"))
    this.campaignService.getUserDetail().subscribe(
      (res: any) => {
        // console.log(res);
        if (res) {
          this.role =
            res.role[0] === undefined || res.role[0].role === undefined
              ? ""
              : res.role[0].role;
          this.industry =
            res.industry[0] === undefined ||
            res.industry[0].industry === undefined
              ? ""
              : res.industry[0].industry;
          this.campaignService.createCampaignRequest.keywords =
            this.campaignService.createCampaignRequest.keywords === undefined
              ? createCampaignSessionData && createCampaignSessionData.keywords ||   res.firstName + " " + res.lastName
              : this.campaignService.createCampaignRequest.keywords;
          // tslint:disable-next-line:max-line-length
          this.campaignService.createCampaignRequest.textADHeadline1 =
            this.campaignService.createCampaignRequest.textADHeadline1 ===
            undefined
              ? createCampaignSessionData && createCampaignSessionData.textADHeadline1 ||  this.role
              : this.campaignService.createCampaignRequest.textADHeadline1;
          // tslint:disable-next-line:max-line-length
          
          this.campaignService.createCampaignRequest.textADHeadline3 = 
          this.campaignService.createCampaignRequest.textADHeadline3 === undefined ?  
          createCampaignSessionData && createCampaignSessionData.textADHeadline3  ||  this.campaignService.createCampaignRequest.textADHeadline3 : this.campaignService.createCampaignRequest.textADHeadline3 ;
          
          this.campaignService.createCampaignRequest.textADHeadline2 =
            this.campaignService.createCampaignRequest.textADHeadline2 ===
            undefined
              ? createCampaignSessionData && createCampaignSessionData.textADHeadline2 ||   this.industry
              : this.campaignService.createCampaignRequest.textADHeadline2;
          this.campaignService.createCampaignRequest.customerName =
            this.campaignService.createCampaignRequest.customerName ===
            undefined
              ? res.firstName + " " + res.lastName 
              : this.campaignService.createCampaignRequest.customerName;
          this.campaignService.createCampaignRequest.customerEmail =
            this.campaignService.createCampaignRequest.customerEmail ===
            undefined
              ? res.email
              : this.campaignService.createCampaignRequest.customerEmail;
          this.campaignService.createCampaignRequest.customerContact =
            this.campaignService.createCampaignRequest.customerContact ===
            undefined
              ? res.mobile
              : this.campaignService.createCampaignRequest.customerContact;
          this.campaignService.createCampaignRequest.linkedInUrl =
           
          this.campaignService.createCampaignRequest.linkedInUrl === undefined
              ? createCampaignSessionData && createCampaignSessionData.linkedInUrl || "www.linkedin.com/in/yourID"
              : this.campaignService.createCampaignRequest.linkedInUrl;
         
              this.campaignService.createCampaignRequest.websiteUrl =
            this.campaignService.createCampaignRequest.websiteUrl === undefined
              ? createCampaignSessionData && createCampaignSessionData.websiteUrl ||  "www.xyz.com"
              : this.campaignService.createCampaignRequest.websiteUrl;
          this.campaignService.isUserHaveCampaign =
            res.isCampaign === 1 ? 1 : 0;
        }
      },
      (error) => {
        // console.log('got an error' + error);
      }
    );
  }

  doLogout() {
    this.authService.logout().subscribe(
      (res) => {
        if (res) {
          // console.log('success' + JSON.stringify(res));
          // localStorage.clear();
          // this.router.navigate(['/auth/login']);
          this.campaignService.createCampaignRequest.keywords = undefined;
          this.campaignService.createCampaignRequest.textADHeadline1 =
            undefined;
          this.campaignService.createCampaignRequest.textADHeadline2 =
            undefined;
          this.campaignService.createCampaignRequest.textADHeadline3 =
            undefined;
          this.campaignService.createCampaignRequest.linkedInUrl = undefined;
          this.campaignService.createCampaignRequest.websiteUrl = undefined;
          this.campaignService.createCampaignRequest.description1 = undefined;
          this.campaignService.createCampaignRequest.description2 = undefined;
          this.qnaryservice.isqnaryAuthenticated = false;
          this.campaignService.createCampaignRequest.customerName = undefined;
          this.campaignService.createCampaignRequest.customerEmail = undefined;
          this.campaignService.createCampaignRequest.customerContact =
            undefined;
          this.campaignService.createCampaignRequest.customersZipcode =
            undefined;
          this.authService.logoutForMalformedToken();
        } else {
          // console.log('got an exception'  + JSON.stringify(res));
        }
      },
      (error) => {
        // console.log('exce' + JSON.stringify(error))
      }
    );
  }

  // openDailog() {
  //   this.loaderDialogRef = this.dailog.open(CampaignSuccessPromtComponent, {
  //     height: 'auto',
  //     width: '50vw',
  //     maxWidth: '100vw',
  //   });
  //
  //   this.loaderDialogRef.afterClosed().subscribe(
  //     data => {}
  //       // console.log('Dialog output:', data)
  //   );
  // }

  validateStepSelection(event, stepper) {
    // console.log('event >>> ' + JSON.stringify(event));
    // console.log('stepper >>> ' + JSON.stringify(stepper));
  }
}
