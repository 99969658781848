export const PlansDetails = {
  price_level: {
    first_level: {
      price: 30,
      clicks: 10,
      month: 1
    } ,
    second_level: {
      price: 50,
      clicks: 20,
      month: 3
    } ,
    thirld_level: {
      price: 100,
      clicks: 50,
      month: 6
    }
  },
  subscriptions: {
    ONE_MONTH_SUBSCRIPTION: 'one month subscription',
    THREE_MONTHS_SUBSCRIPTION: 'three months subscription',
    SIX_MONTHS_SUBSCRIPTION: 'six months subscription'
  }
};
