import {NgModule} from '@angular/core';
import {ROUTES, RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './shared/components/home/home.component';
import {AuthGuardService} from './core/auth/auth-guard.service';
import {MainLayoutComponent} from './shared/layout/main-layout/main-layout.component';
import {AuthCanloadGuardService} from './core/auth/auth-canload-guard.service';
import {AuthGuardDeactivateService} from './core/auth/auth-guard-deactivate.service';
import {OverviewComponent} from './shared/components/overview/overview.component';
import {SharedModule} from './shared/shared.module';
import {FaqComponent} from './shared/components/faq/faq.component';
import {CampaignComponent} from './shared/components/campaign/campaign.component';
import {PackageComponent} from './shared/components/package/package.component';
import {SummaryComponent} from './shared/components/summary/summary.component';
import {PaymentComponent} from './shared/components/payment/payment.component';
import {HowItWorksComponent} from './shared/components/how-it-works/how-it-works.component';
import {CustomersComponent} from './shared/components/customers/customers.component';
import {BenefitsComponent} from './shared/components/benefits/benefits.component';
import {PricingComponent} from './shared/components/pricing/pricing.component';
import {UpdatePasswordComponent} from "./shared/components/update-password/update-password.component";
import {ProfileComponent} from "./shared/components/profile/profile.component";
import {PaymentInfoComponent} from './shared/components/payment-info/payment-info.component';
import {BillingHistoryComponent} from './shared/components/billing-history/billing-history.component';
import {PaymentCheckoutComponent} from './campaign/payment-checkout/payment-checkout.component';
import {AddUpdateCarddetailsComponent} from './shared/components/add-update-carddetails/add-update-carddetails.component';
import {AdminDashboardComponent} from './admin/admin-dashboard/admin-dashboard.component';
import {QnaryloginComponent} from './qnary/qnarylogin/qnarylogin.component';
import {AlertBoxComponent} from './shared/components/alert-box/alert-box.component';
import { BlogComponent } from './shared/components/blog/blog.component';
import { BlogViewComponent } from './shared/components/blog-view/blog-view.component';


const standardRoutes: Routes = [
  {path: '', component: OverviewComponent},
  // {path: 'overview', redirectTo : 'overview#howitwork', pathMatch: 'full'},
  {path: 'faq', component: FaqComponent},
  {path:'blog/:id', component:BlogViewComponent},
  {path: 'blog', component: BlogComponent},
  
  
  {path: 'howitworks', component: HowItWorksComponent},
  {path: 'customers', component: CustomersComponent},
  {path: 'benefits', component: BenefitsComponent},
  {path: 'pricing', component: PricingComponent},
  {path: 'campaign', component: CampaignComponent, canActivate: [AuthGuardService]},
  {path: 'package', component: PackageComponent, canActivate: [AuthGuardService]},
  {path: 'summary', component: SummaryComponent, canActivate: [AuthGuardService]},
  {path: 'payment', component: PaymentComponent, canActivate: [AuthGuardService]},
  {path: 'profile', component: ProfileComponent, canActivate: [AuthGuardService]},
  {path: 'payment-info', component: PaymentInfoComponent, canActivate: [AuthGuardService]},
  {path: 'billing-history', component: BillingHistoryComponent, canActivate: [AuthGuardService]},
  {path: 'deleteAlert', component: AlertBoxComponent, canActivate: [AuthGuardService]},
  {path: 'add-add-update-carddetails', component: AddUpdateCarddetailsComponent, canActivate: [AuthGuardService]},

  {path: '', redirectTo: 'overview', pathMatch: 'full'},
  {path: 'reset/:usersToken', component: UpdatePasswordComponent},
  {
    path: 'main', component: MainLayoutComponent, canActivate: [AuthGuardService], children: [
      {
        path: 'reports', loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
      },
      {
        path: 'campaign', loadChildren: () => import('./campaign/campaign.module').then(m => m.CampaignModule),
      },


    ]
  },
  {
    path: 'qnary', loadChildren: () => import('./qnary/qnary.module').then(m => m.QnaryModule),
  },
  {
    path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    // {
    //   path: 'login', loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
    // },
    // {
    //   path: 'dashboard', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    // },

    // {path: '**', redirectTo: 'campaign'}
    // ]
  },

  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canLoad: [AuthCanloadGuardService],
    canActivate: [AuthGuardDeactivateService]
  },
  {path: '**', redirectTo: 'auth', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(standardRoutes), SharedModule],
  exports: [RouterModule],
  // providers:[
  //   {
  //     provide:  ROUTES,
  //     useFactory: () => {
  //       let routes: Routes  = [];
  //       standardRoutes.push({
  //         path: 'secret',
  //         component: BlogComponent
  //       }
  //       )
  //       routes.forEach((route) =>{
  //         standardRoutes.push(route)
  //       })
  //       return standardRoutes.map(e => e)
  //     },
  //     multi: true
  //   },
   
  // ],
})
export class AppRoutingModule {
}
