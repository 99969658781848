import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Router } from "@angular/router";
import { catchError } from 'rxjs/operators';
import { LoaderDialogService } from '../loader-dialog/loader-dialog.service';
import { MessagesData } from '../../data/message-data';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private router: Router, 
    private loaderDialogService: LoaderDialogService,
    private toastr: ToastrService
  ) { }

  private formatErrors(response: any) {
    return throwError(response);
  }

  private handleLogoutAndRedirect(section: string){
    if(localStorage.getItem(`${section}-token`)) 
      this.toastr.error(MessagesData.SESSION_TOKEN_EXPIRED_MESSAGE)
    if(section == "psem") 
      this.router.navigate([`auth/login`])
    else 
      this.router.navigate([`${section}/login`]);
    localStorage.removeItem(`${section}-token`);
    this.loaderDialogService.close();
  }

  private handleErrors(response: any) {
    if (response.status === 400 && response.error && response.error.message == MessagesData.JWT_TOKEN_ERROR) {
        response.error.message = MessagesData.SESSION_TOKEN_EXPIRED_MESSAGE
        if(response.url.toLowerCase().includes('qnary') || window.location.pathname.toLowerCase().includes('qnary')) {
          this.handleLogoutAndRedirect('qnary')
        } 
        else if (response.url.toLowerCase().includes('admin') || window.location.pathname.toLowerCase().includes('admin')){
          this.handleLogoutAndRedirect('admin')
        } else {
          this.handleLogoutAndRedirect('psem')
        }
    }
    return this.formatErrors(response);
  }

  private request(method: string, apiVersion: string, path: string, body: any = {}, params: HttpParams = new HttpParams()): Observable<any> {
    const url = `${environment.ENDPOINT_URL}${environment.API}${apiVersion}${path}`;
    return this.http.request(method, url, { body, params }).pipe(
      catchError(response => this.handleErrors(response))
    );
  }

  get(apiVersion: string, path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.request('GET', apiVersion, path, {}, params);
  }

  put(apiVersion: string, path: string, body: {} = {}): Observable<any> {
    return this.request('PUT', apiVersion, path, body);
  }

  post(apiVersion: string, path: string, body: {} = {}, options: {} = {}): Observable<any> {
    return this.request('POST', apiVersion, path, body);
  }

  delete(apiVersion: string, path: string, deletedetails): Observable<any> {
    return this.request('DELETE', apiVersion, path);
  }

  getFromStrapi(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    const url = `${environment.STRAPI_ENDPOINT_URL}${environment.API}${path}`;
    return this.http.get(url, { params }).pipe(
      catchError(err => this.handleErrors(err))
    );
  }
}
