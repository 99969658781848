import { Injectable } from '@angular/core';
import { MessagesData } from '../../data/message-data';

@Injectable({
  providedIn: 'root'
})
export class FormErrorHandlerService {

  constructor() {
  }

  getErrorCampaignName(formCtrl) {
    return formCtrl.get('campaignName').hasError('required') ? MessagesData.CAMPAIGN_NAME_REQUIRED : '';
  }

  getErrorCampaignGoal(formCtrl) {
    return formCtrl.get('campaignGoal').hasError('required') ? MessagesData.CAMPAIGN_GOAL_REQUIRED : '';
  }

  getErrorCampaignStartDate(formCtrl) {
    return formCtrl.get('campaignStartDate').hasError('required') ? MessagesData.START_DATE_REQUIRED : '';
  }

  getErrorCampaignEndDate(formCtrl) {
    return formCtrl.get('campaignEndDate').hasError('required') ? MessagesData.END_DATE_REQUIRED : '';
  }

  getErrorCampaignURL(formCtrl) {
    return formCtrl.get('campaignUrl').hasError('required') ? MessagesData.CAMPAIGN_URL_REQUIRED :
      formCtrl.get('campaignUrl').hasError('pattern') ? MessagesData.CAMPAIGN_URL_PATTERN : '';
  }

  getErrorCampaignTargetLocation(formCtrl) {
    return formCtrl.get('campaignTargetLocations').hasError('required') ? MessagesData.TARGET_LOCATION_REQUIRED : '';
  }

  getErrorCampaignLanguage(formCtrl) {
    return formCtrl.get('campaignLanguages').hasError('required') ? MessagesData.CAMPAIGN_LANGUAGE_REQUIRED : '';
  }

  getErrorCampaignBudget(formCtrl) {
    return formCtrl.get('campaignBudget').hasError('required') ? MessagesData.CAMPAIGN_BUDGET_REQUIRED :
      formCtrl.get('campaignBudget').hasError('pattern') ? MessagesData.CAMPAIGN_BUDGET_PATTERN :
        formCtrl.get('campaignBudget').hasError('maxLength') ? MessagesData.CAMPAIGN_BUDGET_PATTERN : '';
  }

  getErrorAdGroupName(formCtrl) {
    return formCtrl.get('adGroupName').hasError('required') ? MessagesData.AD_GROUP_NAME_REQUIRED : '';
  }

  getErrorAdGroupKeywords(formCtrl) {
    return formCtrl.get('adGroupKeywords').hasError('required') ? MessagesData.AD_GROUP_KEYWORDS_REQUIRED : '';
  }

  getErrorKeywordsMatchTypes(formCtrl) {
    return formCtrl.get('keywordsMatchType').hasError('required') ? MessagesData.KEYWORD_MATCH_TYPE_REQUIRED : '';
  }

  getErrorFinalADURL(formCtrl) {
    return formCtrl.get('finalURL').hasError('required') ? MessagesData.FINAL_URL_FOR_AD_REQUIRED :
      formCtrl.get('finalURL').hasError('pattern') ? MessagesData.FINAL_URL_FOR_AD_PATTERN : '';
  }

  getErrorTextADHeadline1(formCtrl) {
    return formCtrl.get('headLine1').hasError('required') ? MessagesData.TEXT_AD_HEADLINE_1_REQUIRED : '';
  }

  getErrorTextADHeadline2(formCtrl) {
    return formCtrl.get('headLine2').hasError('required') ? MessagesData.TEXT_AD_HEADLINE_2_REQUIRED : '';
  }

  getErrorTextADHeadline3(formCtrl) {
    return formCtrl.get('optionalHeadLine').hasError('required') ? MessagesData.TEXT_AD_HEADLINE_3_REQUIRED : '';
  }

  getErrorDisplayPathURL(formCtrl) {
    return formCtrl.get('displayPathUrl').hasError('required') ? MessagesData.DISPLAY_PATH_URL_REQUIRED :
      formCtrl.get('displayPathUrl').hasError('pattern') ? MessagesData.DISPLAY_PATH_URL_PATTERN : '';
  }

  getErrorADDescription1(formCtrl) {
    return formCtrl.get('adDescription1').hasError('required') ? MessagesData.DESCRIPTION_1_REQUIRED : '';
  }

  getErrorADDescription2(formCtrl) {
    return formCtrl.get('adDescription2').hasError('required') ? MessagesData.DESCRIPTION_2_REQUIRED : '';
  }

  getErrorKeywords(formCtrl) {
    return formCtrl.get('keywords').hasError('required') ? MessagesData.KEYWORDS_REQUIRED : '';
  }

  getErrorCampaignDailyBudget(formCtrl) {
    return formCtrl.get('campaignDailyBudget').hasError('required') ? MessagesData.CAMPAIGN_DAILY_BUDGET_REQUIRED :
      formCtrl.get('campaignDailyBudget').hasError('pattern') ? MessagesData.CAMPAIGN_DAILY_BUDGET_PATTERN :
        formCtrl.get('campaignDailyBudget').hasError('maxLength') ? MessagesData.CAMPAIGN_DAILY_BUDGET_PATTERN : '';
  }

  getErrorCampaignOverAllBudget(formCtrl) {
    return formCtrl.get('campaignOverallBudget').hasError('required') ? MessagesData.CAMPAIGN_OVERALL_BUDGET_REQUIRED :
      formCtrl.get('campaignOverallBudget').hasError('pattern') ? MessagesData.CAMPAIGN_OVERALL_BUDGET_PATTERN :
        formCtrl.get('campaignOverallBudget').hasError('maxLength') ? MessagesData.CAMPAIGN_OVERALL_BUDGET_PATTERN : '';
  }

  getErrorEmail(formCtrl) {
    return formCtrl.get('email').hasError('required') ? MessagesData.EMAIL_REQUIRED :
      formCtrl.get('email').hasError('pattern') ? MessagesData.NOT_VALID_EMAIL :
        formCtrl.get('email').hasError('email') ? MessagesData.NOT_VALID_EMAIL :
          formCtrl.get('email').hasError('alreadyInUse') ? MessagesData.EMAIL_ALREADY_USE : '';
  }

  getErrorPassword(formCtrl) {
    return formCtrl.get('password').hasError('required') ? MessagesData.PASSWORD_REQUIRED :
      formCtrl.get('password').hasError('requirements') ? MessagesData.PASSWORD_PATTERN : '';
  }
}
