import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../core/auth/auth.service';
import {TemplateFieldConstants} from '../../../core/data/message-data';
import {Route, Router} from "@angular/router";
import {CampaignService} from "../../../core/services/campaign/campaign.service";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  avatarImg = '../../../assets/i18n/avatars/avatar1.png';
  headerTitle = 'Personal SEM';
  authenticated = false;
  formFieldConstants = TemplateFieldConstants;

  constructor(public authService: AuthService,
              private router: Router, private campaignService : CampaignService) {

  }

  ngOnInit() {
    this.authenticated = this.authService.isAuthenticated;
    this.campaignService.hideHeader = true
    this.authService.authenticationUpdate.subscribe(isAuthenticated => this.authenticated = isAuthenticated,
      err => {}
        // console.log('Failed to update authentication status')
    );
  }

  logout(): void {
    // this.authService.logout();
    this.authService.logout().subscribe( res => {
      if ( res ) {
        // console.log('success' + JSON.stringify(res));
        localStorage.clear();
        this.router.navigate(['/auth/login']);
      } else {
        // console.log('got an exception'  + JSON.stringify(res));
      } } , error => {
      // console.log('exce' + JSON.stringify(error));
    });
  }
}
