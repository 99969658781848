import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { SharedService } from "../../../core/services/shared-service/shared.service";
import { CampaignService } from "../../../core/services/campaign/campaign.service";
import { Router, NavigationExtras } from "@angular/router";
import { AuthService } from "src/app/core/auth/auth.service";

@Component({
  selector: "app-main-layout",
  templateUrl: "./main-layout.component.html",
  styleUrls: ["./main-layout.component.scss"],
})
export class MainLayoutComponent implements OnInit {
  constructor(
    private location: Location,
    private sharedService: SharedService,
    private campaignService: CampaignService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.getUserDetails();
  }

  navigateBack() {
    this.location.back();
  }

  getUserDetails() {
    const navigationExtras: NavigationExtras = {
      replaceUrl: true, // Clears the navigation history
    };
    this.sharedService.getUserDetails().subscribe(
      (res: any) => {
        // console.log(res);
        if (res && res.data) {
          this.sharedService.setUSer(res.data);
        }
        // this.location.replaceState("/campaign")
        // window.location.href = window.location.origin+"/campaign";
        // console.log("routing ")
      
        if(window.location.pathname === '/main'){
          this.router.navigate(["/campaign"], navigationExtras);
        }
      },
      (error) => {
        this.authService.logoutForMalformedToken()
        // this.router.navigate(["/"], navigationExtras);
        // console.log('got an error' + error);
      }
    );
  }
}
