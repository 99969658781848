import {Injectable} from '@angular/core';
import {LoaderDialogComponent} from '../../../shared/components/loader-dialog/loader-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material';
import {Router} from '@angular/router';
import {MessagesData} from '../../data/message-data';

@Injectable({
  providedIn: 'root'
})
export class LoaderDialogService {
  public loaderDialogRef: any;


  constructor(
    private router: Router,
    private dialog: MatDialog
  ) {
  }

  open(message?): void {

    this.loaderDialogRef = this.dialog.open(LoaderDialogComponent, {
      disableClose: false,
      data: {messageContext: `${message || MessagesData.LOADING}.`}
    });
  }

  close() {
    if (this.loaderDialogRef) {
      this.loaderDialogRef.close();
    }
    this.closeAll();
  }
  closeOne() {
    if (this.loaderDialogRef) {
      this.loaderDialogRef.close();
    }
  }

  closeAll() {
    this.dialog.closeAll();
  }

  changeMessage(message) {
    if (!this.loaderDialogRef || (this.loaderDialogRef && this.loaderDialogRef.getState() > 0)) {
      this.open(message);
    } else {
      this.loaderDialogRef.componentInstance.textBody = `${message || MessagesData.LOADING}.`;
    }
  }
}
