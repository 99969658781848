// Goal for campaign
export const campaignGoals: Array<any> = [
  {
    id: 1,
    text: 'Sales'
  },
  {
    id: 2,
    text: 'Leads'
  },
  {
    id: 3,
    text: 'Website traffic'
  },
  {
    id: 4,
    text: 'Product and band consideration'
  },
  {
    id: 5,
    text: 'Brand awareness and reach'
  },
  {
    id: 6,
    text: 'App promotion'
  }
];

// Keyword match types
export const keywordMatchTypes: Array<any> = [
  {id: 1,
    text: 'Broad match'
  },
  {
    id: 2,
    text: 'Phrase match'
  },
  {
    id: 3,
    text: 'Exact match'
  }
]
