import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {CanLoad, Route, Router, UrlSegment} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthCanloadGuardService implements CanLoad {


  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    let url: string = route.path;
    // console.log('Url:' + url);
    if (this.authService.isAuthenticate()) {
      url = url.replace('auth', 'main');
      // console.log('updated url >> ' + url);
      this.router.navigate([url]);
      return false;
    }
    // this.authService.setRedirectUrl(url);
    // this.router.navigate(['campaign/add']);
    return true;
  }
}
