import {Injectable} from '@angular/core';
import {User} from '../../models/user';
import {ApiService} from '../api/api.service';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {retry} from 'rxjs/operators';
import {HttpClient } from "@angular/common/http";
import {PlansDetails} from "../../../shared/components/package/pricing-and-plans-details";
import * as moment from 'moment';
import {CampaignService} from "../campaign/campaign.service";



@Injectable({
  providedIn: 'root'
})
export class SharedService {
  user: User;
  carddetails: any;
  isaddcarddetails: boolean;
  isEditBillingAddress = false;
 isPaymentDelete=false;
  alert=false;
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private campaignService: CampaignService
  ) {
    this.user = new User();
  }

  setUSer(userDetail) {
    this.user.deserializable(userDetail);
    // console.log(this.user);
  }
  getUser(){
    this.user;
  }
  unset(){
    this.campaignService.createCampaignRequest.cardHolderName = ''
    this.campaignService.createCampaignRequest.street =''
    this.campaignService.createCampaignRequest.street2 =''
    this.campaignService.createCampaignRequest.city = ''
    this.campaignService.createCampaignRequest.state = ''
    this.campaignService.createCampaignRequest.country = ''
    this.campaignService.createCampaignRequest.zipcode = ''
  }

  getUserDetails() : Observable<any> {
    return this.apiService.get(environment.api_version.VERSION_1, 'getUserDetails') ;
  }
  getAllRoles() : Observable<any> {
    return this.apiService.get(environment.api_version.BASIC_API, 'getRoles') ;
  }
  getRolesForAUser() {
    return this.apiService.get(environment.api_version.VERSION_1, 'getRolesForAUser') ;
  }
  getAllIndustries() : Observable<any> {
    return this.apiService.get(environment.api_version.BASIC_API, 'getIndustries') ;
  }
  getIndustriesForAUser() : Observable<any> {
    return this.apiService.get(environment.api_version.VERSION_1, 'getIndustriesForAUser') ;
  }
  updateProfile(registerContext) {
    return this.apiService.post(environment.api_version.BASIC_API, 'updateProfile', registerContext);
  }
  checkEmail(registerContext) {
    return this.apiService.post(environment.api_version.BASIC_API, 'checkEmail', registerContext);
  }
  fetchPaymentDetailsForTheUser() : Observable<any> {
    return this.apiService.post(environment.api_version.VERSION_1, 'fetchPaymentDetailsByUserId');
  }
  addcarddetails(cardDetails): Observable<any> {
    return this.apiService.post(environment.api_version.VERSION_1, 'insertCardDetails', cardDetails);
  }
  updatecarddetails(cardDetails): Observable<any> {
    return this.apiService.post(environment.api_version.VERSION_1, 'updateCardDetails', cardDetails);
  }
  updateBillingAddress(cardDetails): Observable<any> {
    return this.apiService.post(environment.api_version.VERSION_1, 'addOrUpdateBillingAddress', cardDetails);
  }
  deletePaymentDetails(deletedetails) : Observable<any> {
    return this.apiService.post(environment.api_version.VERSION_1, 'deletePayment',deletedetails);
  }

  deleteCampaignDetails(deleteCampaignId) :Observable<any> {
    return this.apiService.post(environment.api_version.VERSION_1, 'delete/'+deleteCampaignId);
  }

  deleteQnaryCampaignDetails(deleteCampaignId, isQnary) :Observable<any> {
    return this.apiService.post(environment.api_version.VERSION_1, 'deleteQnaryCampaign/'+deleteCampaignId, isQnary);
  }

  getBlogs(page: any, pageSize: any) :Observable<any> {
    return this.apiService.getFromStrapi( `blogs/?populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort[0]=id%3Adesc`);
  }

  getBlogsById(id: any) :Observable<any> {
    return this.apiService.getFromStrapi( `blogs/${id}?populate=*`);
  }
  getBlogsByCategory(filter: String, limit:number) :Observable<any> {
    return this.apiService.getFromStrapi( `blogs?populate=*&pagination[page]=1&pagination[pageSize]=${limit}&${filter}`);
  }
  getBlogsByTitle(title: String) :Observable<any> {
    // console.log("path ===> ", `blogs?populate[0]=AuthorImage&populate[1]=Images&pagination[page]=1&pagination[pageSize]=1&filters[Title][$eq]=${title}`)
    return this.apiService.getFromStrapi(`blogs?populate=*&pagination[page]=1&pagination[pageSize]=1&filters[Title][$eq]=${title}`);
  }
  getBlogsExceptCurrentBlog(page: any, pageSize: any, title) :Observable<any> {
    return this.apiService.getFromStrapi( `blogs/?populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[Title][$ne]=${title}&sort[0]=id%3Adesc`);
  }

  getS3Session() :Observable<any> {
    return this.apiService.get('v1/', 'getS3Session');
  }
}
