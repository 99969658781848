import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomMaterialModule} from './custom-modules/custom-material.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpTokenInterceptor} from './interceptors';
import {ApiService} from './services/api/api.service';
import {AuthService} from './auth/auth.service';
import {AuthGuardService} from './auth/auth-guard.service';
import {ToastrModule} from 'ngx-toastr';
import {AuthGuardDeactivateService} from './auth/auth-guard-deactivate.service';
import {AuthCanloadGuardService} from './auth/auth-canload-guard.service';
import {LoaderDialogService} from './services/loader-dialog/loader-dialog.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CustomMaterialModule,
    HttpClientModule,
    ToastrModule.forRoot({progressBar: true, closeButton: true, progressAnimation: 'decreasing'})
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true},
    ApiService,
    AuthService,
    AuthGuardService,
    AuthGuardDeactivateService,
    AuthCanloadGuardService,
    LoaderDialogService
  ],
  exports: [CustomMaterialModule, ToastrModule]
})
export class CoreModule {
}
