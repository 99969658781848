import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-cmpgn-adgroup-mobile-preview',
  templateUrl: './cmpgn-adgroup-mobile-preview.component.html',
  styleUrls: ['./cmpgn-adgroup-mobile-preview.component.scss']
})
export class CmpgnAdgroupMobilePreviewComponent implements OnInit {

  @Input() headline1Txt: string = '';
  @Input() headline2Txt: string = '';
  @Input() finalUrlTxt: string = '';
  @Input() decription1Txt: String = '';

  constructor() { }

  ngOnInit() {
  }

}
