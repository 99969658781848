import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {ApiService} from '../core/services/api/api.service';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../core/auth/auth.service';
import {User} from '../core/models/user';
import {CreateCampaignRequest} from "../core/models/requests/create-campaign-request";

@Injectable({
  providedIn: 'root'
})

export class QnaryService {
  private user: User;
  isqnaryAuthenticated= false;
  dashboard=false;
  updateClicks: any;
  createCampaignRequest: CreateCampaignRequest;
  previewCampaignDetails: any;
  editButton: boolean;
  deleteCampaignId: any;
  isLinkedIn = 1;

  constructor(private apiService: ApiService,
              private http: HttpClient,
              private authService: AuthService) {
    this.user = new User();
    this.createCampaignRequest = new CreateCampaignRequest();
  }
  updateCampaignStatus(qnaryDetail): Observable<any> {
    return this.apiService.post(environment.api_version.VERSION_1, 'updateCampaignStatus', qnaryDetail);
  }
  getAllCampaignForQnaryDashboard(): Observable<any> {
    return this.apiService.get(environment.api_version.VERSION_1, 'getAllCampaignForQnaryDashboard');
  }
  insertCanpaignForQnaryDashboard(campaignDetails): Observable<any> {
    return this.apiService.post(environment.api_version.VERSION_1, 'insertCanpaignForQnaryDashboard',campaignDetails);
  }

  updatingClick(campDetails) {
    return this.apiService.post(environment.api_version.VERSION_1, 'updateClicksinQnaryCampaign',campDetails);
  }

  updateQnaryCampaignDetails(campaignDetails) {
    return this.apiService.post(environment.api_version.VERSION_1, 'updateCampaign',campaignDetails);
  }

  addMoreClicks(campDetails) {
    return this.apiService.post(environment.api_version.VERSION_1, 'addMoreClicksinQnaryCampaign',campDetails);
  }
}
