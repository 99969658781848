import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AuthService} from '../../../core/auth/auth.service';
import {QnaryService} from '../../../qnary/qnary.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-adminlogin-header',
  templateUrl: './adminlogin-header.component.html',
  styleUrls: ['./adminlogin-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminloginHeaderComponent implements OnInit {

  constructor(private authService: AuthService,
              public qnaryservice: QnaryService,private router : Router,
              private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    // this.qnaryservice.dashboard = true
    // this.qnaryservice.isqnaryAuthenticated = true
  }
  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }
  doLogout() {
    this.authService.logout().subscribe( res => {
      if ( res ) {
        // console.log('success' + JSON.stringify(res));
        // localStorage.clear();
        // this.router.navigate(['/auth/login']);
        this.authService.logoutForMalformedToken();
        this.qnaryservice.isqnaryAuthenticated=false;
        this.authService.isAdminAuthenticated=false;
      } else {
        // console.log('got an exception'  + JSON.stringify(res));
      } } , error => {
      // console.log('exce' + JSON.stringify(error))
    });
  }
}
