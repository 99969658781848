// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // ENDPOINT_URL: 'https://b4c7-14-98-32-198.ngrok.io/',
  // ENDPOINT_URL: 'http://34.201.93.164:8080/',
  ENDPOINT_URL: 'https://api.character30.com/',
  STRAPI_ENDPOINT_URL : "https://cms.character30.com/",
  // STRAPI_ENDPOINT_URL : "http://localhost:1337/",
  // ENDPOINT_URL: 'http://18.209.4.195/', //dev server
  // ENDPOINT_URL: 'http://character30ebs-env-1.eba-xmv6kf3m.us-east-1.elasticbeanstalk.com/',
  API: 'api/',
  api_version : {
    BASIC_API : 'basic/',
    VERSION_1: 'V1/',
    PAYMENTS: 'payments/'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
