import {Component, OnInit} from '@angular/core';
import {Elements, ElementsOptions, StripeService} from 'ngx-stripe';
import {Element as StripeElement} from 'ngx-stripe/lib/interfaces/element';
import {ToastrService} from 'ngx-toastr';
import {LoaderDialogService} from '../../../core/services/loader-dialog/loader-dialog.service';
import {TimeoutError} from 'rxjs';
import {Location} from '@angular/common';
import {CampaignService} from '../../../core/services/campaign/campaign.service';
import {AuthService} from '../../../core/auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {LoaderDialogComponent} from '../loader-dialog/loader-dialog.component';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {FormErrorHandlerService} from "../../../core/services/form-error/form-error-handler.service";
import {keywordMatchTypes} from "../../../core/data/mat-dropdown-data";
import {MessagesData, TemplateFieldConstants} from "../../../core/data/message-data";

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
  paymentForm: FormGroup;
  showLoader = false;
  // campaignGoals = campaignGoals;
  keywordsMatchTypes = keywordMatchTypes;
  formErrorHandlerServiceInstance: FormErrorHandlerService;
  formFieldConstants = TemplateFieldConstants;
  selectedFormStep = 0;
  loaderDialogRef: any;
  elements: Elements;
  card: StripeElement;
  cardNumber: StripeElement;
  cardExpiration: StripeElement;
  cardCvc: StripeElement;
  elementsOptions: ElementsOptions = {
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css?family=Quicksand',
      },
    ],
    locale: 'auto',
  };
  private cardElements: StripeElement[];
  form: any;
  error: any;
  name: any;
  stripeElementsInfo: any = {};
  stripeElementResponseAfterValidationOfGivenInput: any;
  validUrl = require('valid-url');
  paymentMethodAndCustomerInfo: any = {};
  next = 1;
  campaignId = 0;
  campaignCreationAllStepsInfo: any = {};
  private datta: any;
  private submitted: boolean;

  constructor(
    private fb: FormBuilder,
    private formErrorHandlerService: FormErrorHandlerService,
    private campaignService: CampaignService,
    private authService: AuthService,
    private router: Router,
    private activeRouter: ActivatedRoute,
    private matDialog: MatDialog,
    private stripeService: StripeService,
    private toastr: ToastrService,
    private loaderDialogService: LoaderDialogService,
    private location: Location
  ) {
  }

  ngOnInit() {
    this.campaignService.createCampaignRequest = sessionStorage.getItem("createCampaignRequestData") && JSON.parse(sessionStorage.getItem("createCampaignRequestData"))
    this.buildPaymentForm();
    this.mountStripeElements();
    this.campaignService.hideHeader = false;
  }

  get f() {
    return this.paymentForm.controls;
  }

  private buildPaymentForm() {
    this.paymentForm = this.fb.group({
      name: new FormControl(this.campaignService.createCampaignRequest.customerName, [Validators.required]),
      email: new FormControl(this.campaignService.createCampaignRequest.customerEmail, [Validators.required]),
      mobile: new FormControl(this.campaignService.createCampaignRequest.customerContact, [Validators.required]),
      zipcode: new FormControl(this.campaignService.createCampaignRequest.customersZipcode, [Validators.required]),
      // cardNumber: new FormControl(this.campaignService.createCampaignRequest.cardNumber, [Validators.required]),
    });
  }

  private mountStripeElements() {
    this.stripeService.elements(this.elementsOptions)
      .subscribe(elements => {
        this.elements = elements;
        if (!this.card) {
          this.cardNumber = this.elements.create('cardNumber', {
            style: {
              base: {
                color: '#505050',
                fontWeight: 500,
                fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
                fontSize: '13px',
                fontSmoothing: 'antialiased',

                ':focus': {
                  color: '#424770',
                },

                '::placeholder': {
                  color: '#7d92bb',
                },
              },
              invalid: {
                color: '#FA755A',
                ':focus': {
                  color: '#FA755A',
                },
                '::placeholder': {
                  color: '#FFCCA5',
                },
              },
            },
            // placeholder: 'Card Number'
          });
          this.cardNumber.mount('#cardNumberRef');
          this.cardNumber.on('change', (event) => {
            // console.log('validation object >>> ' + JSON.stringify(event));
            if (event.error) {
              this.stripeElementResponseAfterValidationOfGivenInput = event.error.message;
              this.stripeElementsInfo.cardNumber = {};
              this.stripeElementsInfo.cardNumber.isValid = false;
              this.stripeElementsInfo.cardNumber.errorMessage = event.error && event.error.message ? event.error.message : 'Unkown stripe error';
            } else if (event.complete) {
              this.stripeElementsInfo.cardNumber = {};
              this.stripeElementsInfo.cardNumber.isValid = true;
              // console.log(this.stripeElementsInfo.cardNumber);
            }
          });
          this.cardExpiration = this.elements.create('cardExpiry', {
            style: {
              base: {
                color: '#505050',
                fontWeight: 500,
                fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
                fontSize: '13px',
                fontSmoothing: 'antialiased',

                ':focus': {
                  color: '#424770',
                },

                '::placeholder': {
                  // color: '#9BACC8',
                  color: '#7d92bb',
                },
              },
              invalid: {
                color: '#FA755A',
                ':focus': {
                  color: '#FA755A',
                },
                '::placeholder': {
                  color: '#FFCCA5',
                },
              },
            },
            // placeholder: ''
            // placeholder: 'Card Expiration'
          });
          this.cardExpiration.mount('#cardExpiryRef');
          this.cardExpiration.on('change', (event) => {
            // console.log('validation object >>> ' + JSON.stringify(event));
            if (event.error) {
              this.stripeElementResponseAfterValidationOfGivenInput = event.error.message;
              this.stripeElementsInfo.cardExpiry = {};
              this.stripeElementsInfo.cardExpiry.isValid = false;
              this.stripeElementsInfo.cardExpiry.errorMessage = event.error && event.error.message ? event.error.message : 'Unkown stripe error';
            } else if (event.complete) {
              this.stripeElementsInfo.cardExpiry = {};
              this.stripeElementsInfo.cardExpiry.isValid = true;
            }
          });
          this.cardCvc = this.elements.create('cardCvc', {
            style: {
              base: {
                color: '#505050',
                fontWeight: 500,
                fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
                fontSize: '13px',
                fontSmoothing: 'antialiased',

                ':focus': {
                  color: '#424770',
                },

                '::placeholder': {
                  color: '#7d92bb',
                },
              },
              invalid: {
                color: '#FA755A',
                ':focus': {
                  color: '#FA755A',
                },
                '::placeholder': {
                  color: '#FFCCA5',
                },
              },
            },
            placeholder:'CVV'
          });
          this.cardCvc.mount('#cardCvcRef');
          this.cardCvc.on('change', (event) => {
            // console.log('validation object >>> ' + JSON.stringify(event));
            if (event.error) {
              this.stripeElementResponseAfterValidationOfGivenInput = '';
              this.stripeElementsInfo.cardCvc = {};
              this.stripeElementsInfo.cardCvc.isValid = false;
              this.stripeElementsInfo.cardCvc.errorMessage = event.error && event.error.message ? event.error.message : 'Unkown stripe error';
            } else if (event.complete) {
              this.stripeElementsInfo.cardCvc = {};
              this.stripeElementsInfo.cardCvc.isValid = true;
            }
          });
        }
      });
    this.cardElements = [this.cardNumber, this.cardExpiration, this.cardCvc];
  }

  saveAndSubmitPaymentForm(value: any) {
    this.submitted = true;

    // stop here if form is invalid
    if (this.paymentForm.invalid) {
      return;
    }
    this.loaderDialogService.closeAll();
    this.loaderDialogService.open(`${MessagesData.PLEASE_WAIT}`);
    // console.log('payment form value >>> ' + JSON.stringify(value));
    // console.log('campaign creation object >>> ' + JSON.stringify(this.campaignService.createCampaignRequest));
    // this.createPaymentMethod();
    this.stripeService.createPaymentMethod('card', this.cardNumber).subscribe(paymentMethodInfo => {
      if (paymentMethodInfo.paymentMethod) {
        // console.log('Payment method Info sucessfully added >>> ' + JSON.stringify(paymentMethodInfo.paymentMethod));
        this.campaignService.paymentMethodInfo = paymentMethodInfo.paymentMethod;
        this.campaignCreationAllStepsInfo = {
          step1CampaignForm: {
            campaignStartDate: this.campaignService.createCampaignRequest.campaignStartDate,
            campaignEndDate: this.campaignService.createCampaignRequest.campaignEndDate,
            campaignDailyBudget: null,
            campaignOverallBudget: this.campaignService.createCampaignRequest.selectedPlanPrice
          },
          step2CampaignForm: {
            keywords: this.campaignService.createCampaignRequest.keywords
          },
          step3CampaignForm: {
            finalADUrl: this.campaignService.createCampaignRequest.finalADUrl,
            textADHeadline1: this.campaignService.createCampaignRequest.textADHeadline1,
            textADHeadline2: this.campaignService.createCampaignRequest.textADHeadline2,
            optionalHeadLine: this.campaignService.createCampaignRequest.textADHeadline3,
            description1: this.campaignService.createCampaignRequest.description1,
            description2: this.campaignService.createCampaignRequest.description2
          },
          step4CampaignForm: {
            CustomerName: this.campaignService.createCampaignRequest.customerName,
            CustomerEmail: this.campaignService.createCampaignRequest.customerEmail,
            CusomerMobile: this.campaignService.createCampaignRequest.customerContact,
            zipcode: this.campaignService.createCampaignRequest.customersZipcode,
            // cardNumber : this.campaignService.createCampaignRequest.cardNumber
          },
          paymentInfoPayload: paymentMethodInfo.paymentMethod,
          customerInfoPayload: paymentMethodInfo.paymentMethod.billing_details
        };
        this.loaderDialogService.close();
        if (this.stripeElementsInfo && this.stripeElementsInfo.cardNumber && this.stripeElementsInfo.cardExpiry
          && this.stripeElementsInfo.cardCvc && this.stripeElementsInfo.cardNumber.isValid && this.stripeElementsInfo.cardExpiry.isValid
          && this.stripeElementsInfo.cardExpiry.isValid) {
          // this.campaignService.submitCreateCampiagnForm(this.campaignCreationAllStepsInfo).subscribe(result => {
          //   if (result && result.data) {
          //     console.log('data after creating a campaign >>> ' + JSON.stringify(result.data));
          //   } else {
          //     console.log('Unknown error');
          //     this.toastr.error(MessagesData.SOMETHING_WENT_WRONG);
          //   }
          // }, err => {
          //   this.toastr.error(err && err.error && err.error.message || MessagesData.SERVER_ERROR);
          // });
          // stepper.next();
          // sessionStorage.setItem("createCampaignRequestData", JSON.stringify(this.campaignService.createCampaignRequest))
          sessionStorage.setItem("paymentMethodInfo", JSON.stringify(this.campaignService.paymentMethodInfo))
          this.router.navigate(['/summary']);
        } else {
          this.toastr.error('Unable to create campaign, please check the payment info');
        }
      } else if (paymentMethodInfo.error) {
        this.loaderDialogService.close();
        // console.log('Error in creating payment method >>> ' + JSON.stringify(paymentMethodInfo.error));
        // this.campaignCre   ationAllStepsInfo.paymentInfoPayload = paymentMethodInfo.paymentMethod;
        this.toastr.error(paymentMethodInfo.error.message, 'Error');
      }
    });

    // this.router.navigate(['/summary']);
  }

  createPaymentMethod() {
    const paymentMethodData = {type: 'card'};
    // console.log('Payment method Info sucessfully added >>> ' + JSON.stringify(this.cardNumber));
    // console.log('campaign creation object >>> ' + JSON.stringify(this.campaignService.createCampaignRequest));
    this.stripeService.createPaymentMethod('card', this.cardNumber).subscribe(paymentMethodInfo => {
      if (paymentMethodInfo.paymentMethod) {
        // console.log('Payment method Info sucessfully added >>> ' + JSON.stringify(paymentMethodInfo.paymentMethod));
        this.campaignService.paymentMethodInfo = paymentMethodInfo.paymentMethod;
        sessionStorage.setItem("paymentMethodInfo", JSON.stringify(this.campaignService.paymentMethodInfo))
        this.campaignCreationAllStepsInfo = {
          step1CampaignForm: {
            campaignStartDate: this.campaignService.createCampaignRequest.campaignStartDate,
            campaignEndDate: this.campaignService.createCampaignRequest.campaignEndDate,
            campaignDailyBudget: null,
            campaignOverallBudget: this.campaignService.createCampaignRequest.selectedPlanPrice
          },
          step2CampaignForm: {
            keywords: this.campaignService.createCampaignRequest.keywords
          },
          step3CampaignForm: {
            finalADUrl: this.campaignService.createCampaignRequest.finalADUrl,
            textADHeadline1: this.campaignService.createCampaignRequest.textADHeadline1,
            textADHeadline2: this.campaignService.createCampaignRequest.textADHeadline2,
            optionalHeadLine: this.campaignService.createCampaignRequest.textADHeadline3,
            description1: this.campaignService.createCampaignRequest.description1,
            description2: this.campaignService.createCampaignRequest.description2
          },
          step4CampaignForm: {
            CustomerName: this.campaignService.createCampaignRequest.customerName,
            CustomerEmail: this.campaignService.createCampaignRequest.customerEmail,
            CusomerMobile: this.campaignService.createCampaignRequest.customerContact,
            zipcode: this.campaignService.createCampaignRequest.customersZipcode,
            // cardNumber : this.campaignService.createCampaignRequest.cardNumber
          },
          paymentInfoPayload: paymentMethodInfo.paymentMethod,
          customerInfoPayload: paymentMethodInfo.paymentMethod.billing_details
        };
        if (this.stripeElementsInfo && this.stripeElementsInfo.cardNumber && this.stripeElementsInfo.cardExpiry
          && this.stripeElementsInfo.cardCvc && this.stripeElementsInfo.cardNumber.isValid && this.stripeElementsInfo.cardExpiry.isValid
          && this.stripeElementsInfo.cardExpiry.isValid) {
          // this.campaignService.submitCreateCampiagnForm(this.campaignCreationAllStepsInfo).subscribe(result => {
          //   if (result && result.data) {
          //     console.log('data after creating a campaign >>> ' + JSON.stringify(result.data));
          //   } else {
          //     console.log('Unknown error');
          //     this.toastr.error(MessagesData.SOMETHING_WENT_WRONG);
          //   }
          // }, err => {
          //   this.toastr.error(err && err.error && err.error.message || MessagesData.SERVER_ERROR);
          // });
          // stepper.next();
        } else {
          this.toastr.error('Unable to create campaign, please check the payment info');
        }
      } else if (paymentMethodInfo.error) {
        // console.log('Error in creating payment method >>> ' + JSON.stringify(paymentMethodInfo.error));
        // this.campaignCreationAllStepsInfo.paymentInfoPayload = paymentMethodInfo.paymentMethod;
        this.toastr.error(paymentMethodInfo.error.message, 'Error');
      }
    });
  }


  method_name(value: any) {

    // console.log(value)
  }

  // getErrorMessage() {
  //   this.submitted = true;
  //
  //   // stop here if form is invalid
  //   if (this.paymentForm.invalid) {
  //     return;
  //   }
  // }
}
