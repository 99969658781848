import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MatPaginator, MatTableDataSource} from "@angular/material";
import {ClickDetailsTableHeader} from "../../core/data/mat-table-data";
import {TemplateFieldConstants} from "../../core/data/message-data";
import {ReportService} from "../../core/services/report/report.service";
import {Router} from "@angular/router";
import {LoaderDialogService} from "../../core/services/loader-dialog/loader-dialog.service";
import {MatSort} from '@angular/material/sort';
import {CampaignService} from "../../core/services/campaign/campaign.service";

@Component({
  selector: 'app-display-clicks',
  templateUrl: './display-clicks.component.html',
  styleUrls: ['./display-clicks.component.scss']
})
export class DisplayClicksComponent implements OnInit {
  public loaderDialogRef: any;
  displayedColumns: string[] = ClickDetailsTableHeader;
  allClickDetails: any = [];
  dataSource = new MatTableDataSource<any>(this.allClickDetails);
  formFieldConstants = TemplateFieldConstants;
// @ts-ignore
  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor( public dialogRef: MatDialogRef<DisplayClicksComponent>,
               public reportService : ReportService,
               private router: Router,
               private loaderDialogService: LoaderDialogService,
               public dailog: MatDialog,
               private campaignService: CampaignService) { }

  ngOnInit() {
    this.fetchClickDetails()
  }
  close() {
    // this.dialogRef.closeAll();
    this.dialogRef.close();

  }

  private fetchClickDetails() {
    this.allClickDetails = this.campaignService.clickDetails;
    this.allClickDetails.sort((a, b) => (a.clickedDate > b.clickedDate ? -1 : 1));
    this.paginator._changePageSize(5);
    this.dataSource.data = this.allClickDetails;
    this.dataSource.paginator = this.paginator;
  }
}
