import {Injectable, OnInit} from '@angular/core';
import {CreateCampaignRequest} from '../../models/requests/create-campaign-request';
import {ApiService} from '../api/api.service';
import {AuthService} from '../../auth/auth.service';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {PlansDetails} from "../../../shared/components/package/pricing-and-plans-details";
import * as moment from "moment";
import {Element as StripeElement} from "ngx-stripe/lib/interfaces/element";

@Injectable({
  providedIn: 'root'
})
export class CampaignService implements  OnInit {

  createCampaignRequest: CreateCampaignRequest;
  createcampaignFormStep: number;
      paymentMethodInfo: any;
      campaignDurationDays: any;
      hideHeader: boolean = false;
      type='campaign';
      previewCampaignDetails:any;
  isUserHaveCampaign: any;
  // card: StripeElement;
  // cardNumber: StripeElement;
  // cardExpiration: StripeElement;
  // cardCvc: StripeElement;
  deleteCampaignId: any;
  editButton=false;
  clickDetails: any[];
  clicks: string;
  amount: string;
  campaignId: any;
  keyword: any;
  campaignDetails: any;
  isQnaryCampaign: number;
  isQnary: any;


  constructor(private apiService: ApiService,
              private authService: AuthService) {
    this.createCampaignRequest = new CreateCampaignRequest();
    this.createcampaignFormStep = 0;
  }

  ngOnInit(){
    this.hideHeader = false;
  }

  saveStep1CampaignFormData(step1CampaignFormData) {
    this.createCampaignRequest = step1CampaignFormData;
    // console.log(this.createCampaignRequest);
  }

  saveStep2CampaignFormData(step2CampaignFormData) {
    this.createCampaignRequest = {...this.createCampaignRequest, ...step2CampaignFormData};
    // console.log(this.createCampaignRequest);
  }

  saveStep3CampaignFormData(step3CampaignFormData) {
    this.createCampaignRequest = {...this.createCampaignRequest, ...step3CampaignFormData};
    // console.log(this.createCampaignRequest);
  }

  saveStep4CampaignFormData(step4CampaignFormData) {
    this.createCampaignRequest = {...this.createCampaignRequest, ...step4CampaignFormData};
    // console.log(this.createCampaignRequest);
  }

  submitCreateCampiagnForm(campaignFormInfo: any) {
    return this.apiService.post(environment.api_version.VERSION_1, 'campaignCreationStep1', campaignFormInfo, {});
  }

  getCampaigns(): Observable<any> {
    return this.apiService.get(environment.api_version.VERSION_1, 'getAllUsersCampaigns');
  }
  getCampaignDetails(id: any): Observable<any> {
    return this.apiService.get(environment.api_version.VERSION_1, 'getCampaignDetails/'+id);
  }

  setCampaignCampaignStartAndEndDates(selectedDurationPlan: string){
    if(PlansDetails.subscriptions.ONE_MONTH_SUBSCRIPTION === selectedDurationPlan){
      this.createCampaignRequest.campaignStartDate = moment().format("YYYY-MM-DD HH:mm:ss");
      this.createCampaignRequest.campaignEndDate = moment().add(1, 'month').format("YYYY-MM-DD HH:mm:ss");
      this.campaignDurationDays = moment(this.createCampaignRequest.campaignEndDate).diff(this.createCampaignRequest.campaignStartDate);
      // console.log('this.campaignDurationDays >> ' + JSON.stringify(this.campaignDurationDays));
    } else if(PlansDetails.subscriptions.THREE_MONTHS_SUBSCRIPTION === selectedDurationPlan){
      this.createCampaignRequest.campaignStartDate = moment().format("YYYY-MM-DD HH:mm:ss");
      this.createCampaignRequest.campaignEndDate = moment().add(3, 'month').format("YYYY-MM-DD HH:mm:ss");
      this.campaignDurationDays = moment(this.createCampaignRequest.campaignEndDate).diff(this.createCampaignRequest.campaignStartDate, 'days');
      this.createCampaignRequest.plansDiscountPercent = 5;
      // console.log('this.campaignDurationDays >> ' + JSON.stringify(this.campaignDurationDays));
    } else if(PlansDetails.subscriptions.SIX_MONTHS_SUBSCRIPTION === selectedDurationPlan){
      this.createCampaignRequest.campaignStartDate = moment().format("YYYY-MM-DD HH:mm:ss");
      this.createCampaignRequest.campaignEndDate = moment().add(6, 'month').format("YYYY-MM-DD HH:mm:ss");
      this.campaignDurationDays = moment(this.createCampaignRequest.campaignEndDate).diff(this.createCampaignRequest.campaignStartDate);
      this.createCampaignRequest.plansDiscountPercent = 10;
      // console.log('this.campaignDurationDays >> ' + JSON.stringify(this.campaignDurationDays));
    }
  }

  setCampaignPlanPriceLevels(price: number) {
    if(PlansDetails.price_level.first_level.price === price){
      this.createCampaignRequest.selectedPlanPrice = price;
      this.createCampaignRequest.selectedPlansNoOfClicks = PlansDetails.price_level.first_level.clicks;
      this.createCampaignRequest.campaignStartDate = moment().format("YYYY-MM-DD HH:mm:ss");
      this.createCampaignRequest.campaignEndDate = moment().add(30, 'days').format("YYYY-MM-DD HH:mm:ss");
      this.campaignDurationDays = moment(this.createCampaignRequest.campaignEndDate).diff(this.createCampaignRequest.campaignStartDate);
      this.createCampaignRequest.selectedPlanDuration = 'one month subscription';
      this.createCampaignRequest.selectedPlanMonth = 1;
      // console.log('this.createCampaignRequest >> ' + JSON.stringify(this.createCampaignRequest));
    } else if(PlansDetails.price_level.second_level.price === price){
      this.createCampaignRequest.selectedPlanPrice = price;
      this.createCampaignRequest.selectedPlansNoOfClicks = PlansDetails.price_level.second_level.clicks;
      this.createCampaignRequest.campaignStartDate = moment().format("YYYY-MM-DD HH:mm:ss");
      this.createCampaignRequest.campaignEndDate = moment().add(1, 'month').format("YYYY-MM-DD HH:mm:ss");
      this.campaignDurationDays = moment(this.createCampaignRequest.campaignEndDate).diff(this.createCampaignRequest.campaignStartDate, 'days');
      this.createCampaignRequest.plansDiscountPercent = 5;
      this.createCampaignRequest.selectedPlanDuration = 'one month subscription';
      this.createCampaignRequest.selectedPlanMonth = 1;
      // console.log('this.createCampaignRequest >> ' + JSON.stringify(this.createCampaignRequest));
    } else if(PlansDetails.price_level.thirld_level.price === price){
      this.createCampaignRequest.selectedPlanPrice = price;
      this.createCampaignRequest.selectedPlansNoOfClicks = PlansDetails.price_level.thirld_level.clicks;
      this.createCampaignRequest.campaignStartDate = moment().format("YYYY-MM-DD HH:mm:ss");
      this.createCampaignRequest.campaignEndDate = moment().add(1, 'month').format("YYYY-MM-DD HH:mm:ss");
      this.campaignDurationDays = moment(this.createCampaignRequest.campaignEndDate).diff(this.createCampaignRequest.campaignStartDate);
      this.createCampaignRequest.plansDiscountPercent = 10;
      this.createCampaignRequest.selectedPlanDuration = 'one month subscription';
      this.createCampaignRequest.selectedPlanMonth = 1;
      // console.log('this.createCampaignRequest >> ' + JSON.stringify(this.createCampaignRequest));
    }
  }


  getUserDetail(): Observable<any> {
    return this.apiService.get(environment.api_version.VERSION_1, 'getUserDetails');
  }

  fetchPaymentmethod(): Observable<any> {
    return this.apiService.get(environment.api_version.VERSION_1, 'fetchPaymentmethod');
  }

  updateCampaignDetails(campaignDetails) {
    return this.apiService.post(environment.api_version.VERSION_1, 'updateCampaign',campaignDetails);
  }
  getReportDetails(): Observable<any> {
    return this.apiService.get(environment.api_version.VERSION_1, 'getCampaignReportDetails');
  }

  updateCampaignStatus(campaignDetail): Observable<any> {
    return this.apiService.post(environment.api_version.VERSION_1, 'updateCampaignStatus', campaignDetail);
  }

  addAdditonalClicksOnCampaign(campaignDetail):Observable<any>{
    return this.apiService.post(environment.api_version.VERSION_1, 'addAdditonalClicksOnCampaign',campaignDetail);
  }

  fetchClicksDetailsByCamapignId(campaignDetail: any) {
    return this.apiService.post(environment.api_version.VERSION_1, 'fetchClicksDetailsByCamapignId',campaignDetail);
  }

  fetchClicksQnaryDetailsByCamapignId(campaignDetail: any) {
    return this.apiService.post(environment.api_version.VERSION_1, 'fetchClickDetailsByKeyword',campaignDetail);
  }
  fetchCampaignHistoryByCamapignId(campaignDetail: any) {
    return this.apiService.post(environment.api_version.VERSION_1, 'fetchCampaignHistoryByCamapignId',campaignDetail);

  }
}
