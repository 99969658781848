import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {ApiService} from "../api/api.service";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../../auth/auth.service";
import {User} from "../../models/user";

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  user: User;
  clicks: string;
  amount: string;
  campaignId: any;
  keyword: any;
  clickDetails: any;
  campaignDetails: any;
  constructor(private apiService: ApiService,
              private http: HttpClient,
              private authService: AuthService) {
    this.user = new User();

  }

  getReportDetails(): Observable<any> {
    return this.apiService.get(environment.api_version.VERSION_1, 'getCampaignReportDetails');
  }

  updateCampaignStatus(campaignDetail): Observable<any> {
    return this.apiService.post(environment.api_version.VERSION_1, 'updateCampaignStatus', campaignDetail);
  }

  addAdditonalClicksOnCampaign(campaignDetail):Observable<any>{
    return this.apiService.post(environment.api_version.VERSION_1, 'addAdditonalClicksOnCampaign',campaignDetail);
  }

  fetchClicksDetailsByCamapignId(campaignDetail: any) {
    return this.apiService.post(environment.api_version.VERSION_1, 'fetchClicksDetailsByCamapignId',campaignDetail);
  }

  fetchCampaignHistoryByCamapignId(campaignDetail: any) {
    return this.apiService.post(environment.api_version.VERSION_1, 'fetchCampaignHistoryByCamapignId',campaignDetail);

  }
}

