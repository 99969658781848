import {Component, ElementRef, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {campaignGoals, keywordMatchTypes} from '../../../core/data/mat-dropdown-data';
import {FormErrorHandlerService} from '../../../core/services/form-error/form-error-handler.service';
import {MessagesData, TemplateFieldConstants} from '../../../core/data/message-data';
import {CampaignService} from '../../../core/services/campaign/campaign.service';
import {AuthService} from '../../../core/auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {LoaderDialogComponent} from '../loader-dialog/loader-dialog.component';
import {RegisterComponent} from '../../../auth/register/register.component';
import {Elements, ElementsOptions, StripeService} from 'ngx-stripe';
import {Element as StripeElement} from 'ngx-stripe/lib/interfaces/element';
import {ToastrService} from 'ngx-toastr';
import {LoaderDialogService} from '../../../core/services/loader-dialog/loader-dialog.service';
import {TimeoutError} from 'rxjs';
import {Location} from '@angular/common';

declare var require: any;

@Component({
  selector: 'app-create-campaign',
  templateUrl: './create-campaign.component.html',
  styleUrls: ['./create-campaign.component.scss'],
})
export class CreateCampaignComponent implements OnInit {
  campaigndetails: any;
  step1CampaignForm: FormGroup;
  step2CampaignForm: FormGroup;
  step3CampaignForm: FormGroup;
  step4CampaignForm: FormGroup;
  campaignCreationAllStepsInfo: any = {};
  showLoader = false;
  campaignGoals = campaignGoals;
  keywordsMatchTypes = keywordMatchTypes;
  formErrorHandlerServiceInstance: FormErrorHandlerService;
  formFieldConstants = TemplateFieldConstants;
  selectedFormStep = 0;
  loaderDialogRef: any;
  elements: Elements;
  card: StripeElement;
  cardNumber: StripeElement;
  cardExpiration: StripeElement;
  cardCvc: StripeElement;
  elementsOptions: ElementsOptions = {
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css?family=Quicksand',
      },
    ],
    locale: 'auto',
  };
  private cardElements: StripeElement[];
  form: any;
  error: any;
  name: any;
  stripeElementsInfo: any = {};
  stripeElementResponseAfterValidationOfGivenInput: any;
  validUrl = require('valid-url');
  paymentMethodAndCustomerInfo: any = {};
  next = 1;
  campaignId = 0;

  constructor(
    private fb: FormBuilder,
    private formErrorHandlerService: FormErrorHandlerService,
    private campaignService: CampaignService,
    private authService: AuthService,
    private router: Router,
    private activeRouter: ActivatedRoute,
    private matDialog: MatDialog,
    private stripeService: StripeService,
    private toastr: ToastrService,
    private loaderDialogService: LoaderDialogService,
    private location: Location
  ) {
    this.formErrorHandlerServiceInstance = this.formErrorHandlerService;
    // console.log(this.validUrl);
  }

  ngOnInit() {
    this.activeRouter.params.subscribe(params => {
      if (params && params.campaignId && (params.campaignId)) {
        this.campaignId = parseInt(params.campaignId, 0);
        this.getCampaignDetails(this.campaignId);
      } else {
        const url = this.router.url || '';
        if (!url.includes('/campaign/add')) {
          this.toastr.error(`${MessagesData.INVALID_CAMPAIGN_ID}`);
          setTimeout(() => this.location.back(), 200);
        }
      }
    });
    this.buildStepCampaignForm();
    // console.log(this.campaignService.createCampaignRequest);
    this.selectedFormStep = this.campaignService.createcampaignFormStep;
  }

  buildStepCampaignForm() {
    this.step1CampaignForm = this.fb.group({
      // campaignName: new FormControl(this.campaignService.createCampaignRequest.campaignName || '', [Validators.required]),
      // campaignGoal: new FormControl(this.campaignService.createCampaignRequest.campaignGoal || '', [Validators.required]),
      campaignStartDate: new FormControl(this.campaignService.createCampaignRequest.campaignStartDate || '', [Validators.required]),
      campaignEndDate: new FormControl(this.campaignService.createCampaignRequest.campaignEndDate || '', [Validators.required]),
      /*campaignUrl: new FormControl(this.campaignService.createCampaignRequest.campaignUrl || 'https://', [Validators.required,
        this.validateWebUrl.bind(this)
        /!*Validators.pattern(/^(http:\/\/|http(s):\/\/?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)*!/]),*/
      // campaignTargetLocations: new FormControl(this.campaignService.createCampaignRequest.campaignTargetLocations || '', [Validators.required]),
      // campaignLanguages: new FormControl(this.campaignService.createCampaignRequest.campaignLanguages || '', [Validators.required]),
      campaignDailyBudget: new FormControl(this.campaignService.createCampaignRequest.campaignDailyBudget || '', [Validators.required, Validators.maxLength(8), Validators.pattern(/^\d+(\.\d{1,2})?$/)]),
      campaignOverallBudget: new FormControl(this.campaignService.createCampaignRequest.campaignOverallBudget || '', [Validators.required, Validators.maxLength(8), Validators.pattern(/^\d+(\.\d{1,2})?$/)])
    });
    this.step2CampaignForm = this.fb.group({
      // adGroupName: new FormControl(this.campaignService.createCampaignRequest.adGroupName || '', [Validators.required]),
      keywords: new FormControl(this.campaignService.createCampaignRequest.keywords || '', [Validators.required]),
      // keywordsMatchType: new FormControl(this.campaignService.createCampaignRequest.keywordsMatchType || '', [Validators.required])
    });
    this.step3CampaignForm = this.fb.group({
      finalADUrl: new FormControl(this.campaignService.createCampaignRequest.finalADUrl || 'https://', [Validators.required, this.validateWebUrl.bind(this)]),
      textADHeadline1: new FormControl(this.campaignService.createCampaignRequest.textADHeadline1 || '', [Validators.required]),
      textADHeadline2: new FormControl(this.campaignService.createCampaignRequest.textADHeadline2 || '', [Validators.required]),
      // textADHeadline3: new FormControl(this.campaignService.createCampaignRequest.textADHeadline3 || '', [Validators.required]),
      displayPathUrl: new FormControl(this.campaignService.createCampaignRequest.displayPathUrl || 'https://', [Validators.required, this.validateWebUrl.bind(this)]),
      description1: new FormControl(this.campaignService.createCampaignRequest.description1 || '', [Validators.required]),
      // description2: new FormControl(this.campaignService.createCampaignRequest.description2 || '', [Validators.required]),
    });
    this.step4CampaignForm = this.fb.group({
      CustomerName: new FormControl('', [Validators.required]),
      CustomerEmail: new FormControl('', [Validators.required]),
      CusomerMobile: new FormControl('', [Validators.required]),
      zipcode: new FormControl('', [Validators.required]),
    });
  }

  editStepCampaignForm() {
    this.step1CampaignForm = this.fb.group({
      // campaignName: new FormControl(this.campaignService.createCampaignRequest.campaignName || '', [Validators.required]),
      // campaignGoal: new FormControl(this.campaignService.createCampaignRequest.campaignGoal || '', [Validators.required]),
      campaignStartDate: new FormControl(this.campaignService.createCampaignRequest.campaignStartDate || new Date(), [Validators.required]),
      campaignEndDate: new FormControl(this.campaignService.createCampaignRequest.campaignEndDate || new Date(), [Validators.required]),
      /*campaignUrl: new FormControl(this.campaignService.createCampaignRequest.campaignUrl || 'https://', [Validators.required,
        this.validateWebUrl.bind(this)
        /!*Validators.pattern(/^(http:\/\/|http(s):\/\/?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)*!/]),*/
      // campaignTargetLocations: new FormControl(this.campaignService.createCampaignRequest.campaignTargetLocations || '', [Validators.required]),
      // campaignLanguages: new FormControl(this.campaignService.createCampaignRequest.campaignLanguages || '', [Validators.required]),
      campaignDailyBudget: new FormControl(this.campaignService.createCampaignRequest.campaignDailyBudget || '1000', [Validators.required, Validators.maxLength(8), Validators.pattern(/^\d+(\.\d{1,2})?$/)]),
      campaignOverallBudget: new FormControl(this.campaignService.createCampaignRequest.campaignOverallBudget || '10000', [Validators.required, Validators.maxLength(8), Validators.pattern(/^\d+(\.\d{1,2})?$/)])
    });
    this.step2CampaignForm = this.fb.group({
      // adGroupName: new FormControl(this.campaignService.createCampaignRequest.adGroupName || '', [Validators.required]),
      keywords: new FormControl(this.campaignService.createCampaignRequest.keywords || 'G', [Validators.required]),
      // keywordsMatchType: new FormControl(this.campaignService.createCampaignRequest.keywordsMatchType || '', [Validators.required])
    });
    this.step3CampaignForm = this.fb.group({
      finalADUrl: new FormControl(this.campaignService.createCampaignRequest.finalADUrl || 'https://www.google.com', [Validators.required, this.validateWebUrl.bind(this)]),
      textADHeadline1: new FormControl(this.campaignService.createCampaignRequest.textADHeadline1 || 'text', [Validators.required]),
      textADHeadline2: new FormControl(this.campaignService.createCampaignRequest.textADHeadline2 || 'text', [Validators.required]),
      // textADHeadline3: new FormControl(this.campaignService.createCampaignRequest.textADHeadline3 || '', [Validators.required]),
      displayPathUrl: new FormControl(this.campaignService.createCampaignRequest.displayPathUrl || 'https://www.google.com', [Validators.required, this.validateWebUrl.bind(this)]),
      description1: new FormControl(this.campaignService.createCampaignRequest.description1 || 'text', [Validators.required]),
      // description2: new FormControl(this.campaignService.createCampaignRequest.description2 || '', [Validators.required]),
    });
    this.step4CampaignForm = this.fb.group({
      CustomerName: new FormControl('vinoth', [Validators.required]),
      CustomerEmail: new FormControl('vin@gmail.com', [Validators.required]),
      CusomerMobile: new FormControl('45478877', [Validators.required]),
      zipcode: new FormControl('45655', [Validators.required]),
    });
  }


  submitStep1Form(step1CampaignFormData = null, stepper) {
    step1CampaignFormData.campaignDailyBudget = step1CampaignFormData.campaignDailyBudget * 100;
    step1CampaignFormData.campaignOverallBudget = step1CampaignFormData.campaignOverallBudget * 100;
    // console.log(step1CampaignFormData);
    this.campaignService.saveStep1CampaignFormData(step1CampaignFormData);
    stepper.next();
  }

  submitStep2Form(step2CampaignFormData, stepper) {
    this.campaignService.saveStep2CampaignFormData(step2CampaignFormData);
    stepper.next();
  }

  submitStep3Form(step3CampaignFormData, stepper) {
    this.campaignService.saveStep3CampaignFormData(step3CampaignFormData);
    /*this.loaderDialogService.open(`${MessagesData.PLEASE_WAIT}. ! ${MessagesData.VALIDATE_AUTHORIZATION_LOADER}`);
    // todo check user was registered or not
    if (this.authService.isAuthenticate()) {
      this.loaderDialogService.changeMessage(`${MessagesData.PLEASE_WAIT}. ! ${MessagesData.CREATE_CAMPAIGN_LOADER}`);
      this.campaignService.submitCreateCampiagnForm(this.campaignService.createCampaignRequest).subscribe(
        res => {
          this.campaignService.createCampaignRequest = this.campaignService.createCampaignRequest.init();
          this.loaderDialogService.close();
          if (res) {
            this.toastr.success(MessagesData.CAMPAIGN_CREATE_REQUEST_SUCCESS);
          } else {
            this.toastr.error(MessagesData.UNKNOWN_ERROR);
          }
        }, err => {
          this.loaderDialogService.close();
          if (err instanceof TimeoutError) {
            return this.toastr.error(MessagesData.REQUEST_TIMEOUT);
          }
          this.toastr.error(err && err.error && err.error.message || MessagesData.SERVER_ERROR);
        }
      );
    } else {
      this.campaignService.createcampaignFormStep = 2;
      this.selectedFormStep = this.campaignService.createcampaignFormStep;
      this.loaderDialogService.close();
      this.router.navigate(['auth/login'], {queryParams: {returnUrl: this.router.url}});
    }*/
    stepper.next();
  }

  submitStep4Form(step4CampaignFormData, stepper) {
    this.campaignService.saveStep4CampaignFormData(step4CampaignFormData);
    this.createPaymentMethod();

  }

  validateStepSelection(evt, stepper) {

    // console.log('validateStepChange4');
    // console.log(evt);
    this.campaignService.createcampaignFormStep = evt.selectedIndex;
    let url: any[] = this.router.url.split('/');
    if (evt.selectedIndex === 3) {
      this.mountStripeElements();
      if (url[url.length - 2] == 'edit') {
      } else {
        // this.campaignService.createcampaignFormStep = evt.previouslySelectedIndex;
        // this.selectedFormStep = this.campaignService.createcampaignFormStep;
      }
      // return;

      this.loaderDialogService.open(`${MessagesData.PLEASE_WAIT}.  ${MessagesData.VALIDATE_AUTHORIZATION_LOADER}`);

      // console.log(this.matDialog.getDialogById('LoaderDialogComponent'));
      if (!this.validateAuthorizationForStep4()) {
        this.campaignService.createcampaignFormStep = evt.previouslySelectedIndex;
        this.selectedFormStep = this.campaignService.createcampaignFormStep;
        // stepper.previous();
        // console.log('>>>>  monting stripe elements >>>> ');
        this.mountStripeElements();
      } else {
        this.loaderDialogService.close();
        this.mountStripeElements();
        // stepper.next();
      }
      // stepper.next();
    }
    // console.log(evt);
  }

  validateAuthorizationForStep4() {
    return this.authService.isAuthenticate();
  }

  mountStripeElements() {
    // console.log('Mounting the stripe values');
    this.stripeService.elements(this.elementsOptions)
      .subscribe(elements => {
        this.elements = elements;
        if (!this.card) {
          this.cardNumber = this.elements.create('cardNumber', {
            style: {
              base: {
                color: '#fff',
                fontWeight: 500,
                fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
                fontSize: '13px',
                fontSmoothing: 'antialiased',

                ':focus': {
                  color: '#424770',
                },

                '::placeholder': {
                  color: '#fff',
                },
              },
              invalid: {
                color: '#FA755A',
                ':focus': {
                  color: '#FA755A',
                },
                '::placeholder': {
                  color: '#FFCCA5',
                },
              },
            },
            placeholder: 'Card Number'
          });
          this.cardNumber.mount('#cardNumberRef');
          this.cardNumber.on('change', (event) => {
            // console.log('validation object >>> ' + JSON.stringify(event));
            if (event.error) {
              this.stripeElementResponseAfterValidationOfGivenInput = event.error.message;
              this.stripeElementsInfo.cardNumber = {};
              this.stripeElementsInfo.cardNumber.isValid = false;
              this.stripeElementsInfo.cardNumber.errorMessage = event.error && event.error.message ? event.error.message : 'Unkown stripe error';
            } else if (event.complete) {
              this.stripeElementsInfo.cardNumber = {};
              this.stripeElementsInfo.cardNumber.isValid = true;
              // console.log(this.stripeElementsInfo.cardNumber);
            }
          });
          this.cardExpiration = this.elements.create('cardExpiry', {
            style: {
              base: {
                color: '#fff',
                fontWeight: 500,
                fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
                fontSize: '13px',
                fontSmoothing: 'antialiased',

                ':focus': {
                  color: '#424770',
                },

                '::placeholder': {
                  // color: '#9BACC8',
                  color: '#fff',
                },
              },
              invalid: {
                color: '#FA755A',
                ':focus': {
                  color: '#FA755A',
                },
                '::placeholder': {
                  color: '#FFCCA5',
                },
              },
            },
            placeholder: ''
            // placeholder: 'Card Expiration'
          });
          this.cardExpiration.mount('#cardExpiryRef');
          this.cardExpiration.on('change', (event) => {
            // console.log('validation object >>> ' + JSON.stringify(event));
            if (event.error) {
              this.stripeElementResponseAfterValidationOfGivenInput = event.error.message;
              this.stripeElementsInfo.cardExpiry = {};
              this.stripeElementsInfo.cardExpiry.isValid = false;
              this.stripeElementsInfo.cardExpiry.errorMessage = event.error && event.error.message ? event.error.message : 'Unkown stripe error';
            } else if (event.complete) {
              this.stripeElementsInfo.cardExpiry = {};
              this.stripeElementsInfo.cardExpiry.isValid = true;
            }
          });
          this.cardCvc = this.elements.create('cardCvc', {
            style: {
              base: {
                color: '#fff',
                fontWeight: 500,
                fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
                fontSize: '13px',
                fontSmoothing: 'antialiased',

                ':focus': {
                  color: '#424770',
                },

                '::placeholder': {
                  color: '#9BACC8',
                },
              },
              invalid: {
                color: '#FA755A',
                ':focus': {
                  color: '#FA755A',
                },
                '::placeholder': {
                  color: '#FFCCA5',
                },
              },
            }
          });
          this.cardCvc.mount('#cardCvcRef');
          this.cardCvc.on('change', (event) => {
            // console.log('validation object >>> ' + JSON.stringify(event));
            if (event.error) {
              this.stripeElementResponseAfterValidationOfGivenInput = '';
              this.stripeElementsInfo.cardCvc = {};
              this.stripeElementsInfo.cardCvc.isValid = false;
              this.stripeElementsInfo.cardCvc.errorMessage = event.error && event.error.message ? event.error.message : 'Unkown stripe error';
            } else if (event.complete) {
              this.stripeElementsInfo.cardCvc = {};
              this.stripeElementsInfo.cardCvc.isValid = true;
            }
          });
        }
      });
    this.cardElements = [this.cardNumber, this.cardExpiration, this.cardCvc];
  }

  createStripeToken() {
    // creating token for the card
    this.stripeService
      .createToken(this.cardNumber, {name})
      .subscribe(result => {
        if (result.token) {
          // todo charge the amount using the stripe token
          // console.log(result.token);
        } else if (result.error) {
          // Error creating the token
          // console.log(result.error.message);
        }
      });
  }

  createPaymentMethod() {
    const name = this.step4CampaignForm.get('CustomerName').value;
    // todo add billing info(customer info) into the billing_details

    const paymentMethodData = {type: 'card', billing_details: {name: 'Raj Madhan'}};
    this.stripeService.createPaymentMethod('card', this.cardNumber).subscribe(paymentMethodInfo => {
      if (paymentMethodInfo.paymentMethod) {
        // console.log('Payment method Info sucessfully added >>> ' + JSON.stringify(paymentMethodInfo.paymentMethod));
        this.campaignCreationAllStepsInfo = {
          step1CampaignForm: this.step1CampaignForm.value,
          step2CampaignForm: this.step2CampaignForm.value,
          step3CampaignForm: this.step3CampaignForm.value,
          step4CampaignForm: this.step4CampaignForm.value,
          paymentInfoPayload: paymentMethodInfo.paymentMethod,
          customerInfoPayload: paymentMethodInfo.paymentMethod.billing_details
        };
        if (this.stripeElementsInfo && this.stripeElementsInfo.cardNumber && this.stripeElementsInfo.cardExpiry
          && this.stripeElementsInfo.cardCvc && this.stripeElementsInfo.cardNumber.isValid && this.stripeElementsInfo.cardExpiry.isValid
          && this.stripeElementsInfo.cardExpiry.isValid) {
          this.campaignService.submitCreateCampiagnForm(this.campaignCreationAllStepsInfo).subscribe(result => {
            if (result && result.data) {
              // console.log('data after creating a campaign >>> ' + JSON.stringify(result.data));
            } else {
              // console.log('Unknown error');
              this.toastr.error(MessagesData.SOMETHING_WENT_WRONG);
            }
          }, err => {
            !(err && err.error && err.error.message == MessagesData.SESSION_TOKEN_EXPIRED_MESSAGE) && this.toastr.error(err && err.error && err.error.message || MessagesData.SERVER_ERROR);
          });
          // stepper.next();
        } else {
          this.toastr.error('Unable to create campaign, please check the payment info');
        }
      } else if (paymentMethodInfo.error) {
        // console.log('Error in creating payment method >>> ' + JSON.stringify(paymentMethodInfo.error));
        this.campaignCreationAllStepsInfo.paymentInfoPayload = paymentMethodInfo.paymentMethod;
        this.toastr.error('Error in creating the payment method', 'Error');
      }
    });
  }

  validateWebUrl(control) {
    if (this.validUrl.isWebUri(control.value)) {
      return null;
    } else {
      return {pattern: true};
    }
  }

  getCampaignDetails(campaignId) {
    this.loaderDialogService.changeMessage(`${MessagesData.PLEASE_WAIT}. ${MessagesData.CAMPAIGN_DATA_FETCHING}...`);
    this.campaignService.getCampaignDetails(campaignId).subscribe(res => {
      this.campaigndetails = res[0];
      this.loaderDialogService.closeAll();
    }, err => {
      this.loaderDialogService.closeAll();
      this.editStepCampaignForm();
    });
  }
}
