import {Component, OnInit, ViewChild} from '@angular/core';
import {CampaignDashboardTableHeaders} from '../../../core/data/mat-table-data';
import {MatDialog, MatTableDataSource, PageEvent, Sort} from '@angular/material';
import {MessagesData, TemplateFieldConstants} from '../../../core/data/message-data';
import {MatPaginator} from '@angular/material/paginator';
import {PaymentCheckoutComponent} from '../../../campaign/payment-checkout/payment-checkout.component';
import {LoaderDialogService} from '../../../core/services/loader-dialog/loader-dialog.service';
 import {AddUpdateCarddetailsComponent} from '../add-update-carddetails/add-update-carddetails.component';
import {SharedModule} from '../../shared.module'
import {SharedService} from "../../../core/services/shared-service/shared.service";
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Observable, of} from 'rxjs';
import {MatSort} from '@angular/material/sort';
import {map} from 'rxjs/operators';
import {AlertBoxComponent} from '../alert-box/alert-box.component';
import {CampaignService} from '../../../core/services/campaign/campaign.service';


@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss']
})
export class PaymentInfoComponent implements OnInit {
  displayedColumns: string[] = ['Credit Card Number',  'Expiration Date', 'CVV', 'actions'];
  allUserCampaigns: any = [];
  dataSource = new MatTableDataSource<any>(this.allUserCampaigns);
  formFieldConstants = TemplateFieldConstants;
  displayedRows: Observable<any>;
  pageEvent: PageEvent;


  // isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;


  private loaderDialogRef: any;
  private deletedetails: any;
  pagesize= 5;
  NoOfCards=false;
  pageIndex = 0;
  private selectedResult: any;
  private pageSizeOptions: number[];
  constructor(private loaderDialogService: LoaderDialogService,
              private router: Router,
              private activeRouter: ActivatedRoute,
              public dailog: MatDialog,
              private toastr: ToastrService,
              private sharedService : SharedService,
              private campaignService:CampaignService) { }
// @ts-ignore
  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
  ngOnInit() {

    // this.allUserCampaigns = this.rows
    this.campaignService.hideHeader = true;
    this.dataSource.data = this.allUserCampaigns;
    this.sharedService.isaddcarddetails=true;

    this.fetchPaymentDetailsForTheUser();
  }


  getData( event?: PageEvent) {
    this.selectedResult = this.allUserCampaigns.slice(event.pageIndex * event.pageSize, event.pageIndex * event.pageSize + event.pageSize);
    this.pageIndex = parseInt(event.pageIndex.toString())
    return event;
  }

  editCampaign(element){
    this.sharedService.isaddcarddetails=false;
    this.sharedService.carddetails=element;
    // console.log(this.sharedService.carddetails)
    this.loaderDialogService.close();
    this.loaderDialogRef = this.dailog.open(AddUpdateCarddetailsComponent, {
      height: '30vw',
      width: '50vw',
      maxWidth: '100vw',
      minHeight : '400px',
      minWidth : '250px'
     });
    this.loaderDialogRef.afterClosed().subscribe(
      data => {
        this.sharedService.unset()
        this.sharedService.carddetails = ''
        this.fetchPaymentDetailsForTheUser();
        this.loaderDialogService.close();
      })
    // this.fetchPaymentDetailsForTheUser();

  }
  addcard(){
    this.sharedService.isaddcarddetails=true;
    this.loaderDialogRef = this.dailog.open(AddUpdateCarddetailsComponent, {
      height: '30vw',
      width: '50vw',
      maxWidth: '100vw',
      minHeight : '400px',
      minWidth : '250px'
    });
    this.loaderDialogRef.afterClosed().subscribe(
      data => {
        this.sharedService.unset()
        this.sharedService.carddetails = ''
        this.fetchPaymentDetailsForTheUser();
        this.loaderDialogService.close();
      })
  }
  editBillingAddress(element){
    this.sharedService.isEditBillingAddress=true;
    this.sharedService.isaddcarddetails=false;
    this.sharedService.carddetails=element;
    this.loaderDialogRef = this.dailog.open(AddUpdateCarddetailsComponent, {
      height: '30vw',
      width: '50vw',
      maxWidth: '100vw',
      minHeight : '400px',
      minWidth : '250px'
    });
    this.loaderDialogRef.afterClosed().subscribe(
      data => {
        this.sharedService.isEditBillingAddress=false;
        this.sharedService.unset()
        this.sharedService.carddetails = ''
        this.fetchPaymentDetailsForTheUser();
        this.loaderDialogService.close();
      })
  }
  private fetchPaymentDetailsForTheUser() {
      this.sharedService.fetchPaymentDetailsForTheUser().subscribe(
        res => {
          // console.log(res);
          let isExpansionDetailRow;
          let rows$;

          if (res) {
            this.loaderDialogService.open(`${MessagesData.REDIRECTING}`);
            // console.log(res);
            this.allUserCampaigns = res;
            this.dataSource.data = this.allUserCampaigns;
            if (this.allUserCampaigns.length == 1) {
              this.NoOfCards = true;
            }
            // console.log(this.allUserCampaigns.length)
            //this.displayedRows = this.allUserCampaigns;
            // this.paginator._changePageSize(this.pagesize);
            // this.dataSource.paginator = this.paginator;
            // const rows$ = of(this.allUserCampaigns);
            // this.totalRows = this.displayedRows;
            // @ts-ignore
            // this.totalRows = rows$.pipe(map(rows => rows.length));
            this.selectedResult = this.allUserCampaigns.slice(this.pageIndex * this.pagesize, this.pageIndex * this.pagesize + this.pagesize);
            this.loaderDialogService.close();
          } else {
            this.loaderDialogService.close();
          }
        },
        err => {
          this.loaderDialogService.close();
          // console.log(err);
        }

      );

  }
  expandedRows: { [key: number]: boolean } = {};
  p: number = 1;
  expand(element) {
    this.expandedRows[element.position] = !this.expandedRows[element.position]
  }

  deleteOneCampaign(element) {
    this.sharedService.isPaymentDelete=true;
    if(this.allUserCampaigns.length == 1){
      this.toastr.error("You can't delete this card until you have another valid card")
    }
    else {
      this.loaderDialogRef = this.dailog.open(AlertBoxComponent, {
        height: '14vw',
        width: '40vw',
        maxWidth: '100vw',
        minHeight : '200px',
        minWidth : '300px'
      });
      this.loaderDialogRef.afterClosed().subscribe(
        data => {
          if (this.sharedService.alert == true) {
            this.loaderDialogService.closeAll();
            this.loaderDialogService.changeMessage('Please wait. Deleting Card Details');
            //this.SharedModule.deletedetails=element.paymentMethodId;
            this.deletedetails = element.paymentMethodId;
            this.sharedService.deletePaymentDetails(element).subscribe(
              res => {

                // console.log(res);
                if (res.message == "success") {
                  // console.log("result",res);
                  this.fetchPaymentDetailsForTheUser();

                  this.router.navigate(['/payment-info']);
                  this.loaderDialogService.close();
                } else {
                  this.toastr.error("Could not delete card");
                  this.loaderDialogService.close();
                }
              },
              err => {
                this.loaderDialogService.close();
                // console.log(err);
                !(err && err.error && err.error.message == MessagesData.SESSION_TOKEN_EXPIRED_MESSAGE) && this.toastr.error(err && err.error && err.error.message || MessagesData.SERVER_ERROR);
              }
            );
            // console.log("message",element)
            this.sharedService.isPaymentDelete=false;
            this.sharedService.alert=false;
          }
          else {
            this.loaderDialogService.close();
            this.sharedService.isPaymentDelete=false;
          }
        });


    }
  }


}
