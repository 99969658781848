import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './layout/header/header.component';
import {CustomMaterialModule} from '../core/custom-modules/custom-material.module';
import {RouterModule} from '@angular/router';
import {AuthLayoutComponent} from './layout/auth-layout/auth-layout.component';
import {CreateCampaignComponent} from './components/create-campaign/create-campaign.component';
import {HomeComponent} from './components/home/home.component';
import {LoaderDialogComponent} from './components/loader-dialog/loader-dialog.component';
import {CmpgnAdgroupMobilePreviewComponent} from './components/cmpgn-adgroup-mobile-preview/cmpgn-adgroup-mobile-preview.component';
import {CmpgnAdgroupDesktopPreviewComponent} from './components/cmpgn-adgroup-desktop-preview/cmpgn-adgroup-desktop-preview.component';
import {CmpgnAdgroupDisplayPreviewComponent} from './components/cmpgn-adgroup-display-preview/cmpgn-adgroup-display-preview.component';

import {NgxStripeModule} from 'ngx-stripe';
import {MainLayoutComponent} from './layout/main-layout/main-layout.component';
import {LoginComponent} from '../auth/login/login.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {SidebarComponent} from './layout/sidebar/sidebar.component';
import {PreloginHeaderComponent} from './layout/prelogin-header/prelogin-header.component';
import {PostloginHearderComponent} from './layout/postlogin-hearder/postlogin-hearder.component';
import {OverviewComponent} from './components/overview/overview.component';
import { FaqComponent } from './components/faq/faq.component';
import { CampaignComponent } from './components/campaign/campaign.component';
import { PackageComponent } from './components/package/package.component';
import { SummaryComponent } from './components/summary/summary.component';
import { PaymentComponent } from './components/payment/payment.component';
import { HowItWorksComponent } from './components/how-it-works/how-it-works.component';
import { CustomersComponent } from './components/customers/customers.component';
import { BenefitsComponent } from './components/benefits/benefits.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { CampaignSuccessPromtComponent } from './components/campaign-success-promt/campaign-success-promt.component';
import {TextMaskModule} from "angular2-text-mask";
import {MatRadioModule} from "@angular/material/radio";
import { UpdatePasswordComponent } from './components/update-password/update-password.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import {ProfileComponent} from "./components/profile/profile.component";
import { PaymentInfoComponent } from './components/payment-info/payment-info.component';
import { BillingHistoryComponent } from './components/billing-history/billing-history.component';
import { AddUpdateCarddetailsComponent } from './components/add-update-carddetails/add-update-carddetails.component';
import { DisplayClicksComponent } from '../campaign/display-clicks/display-clicks.component';
import {MatExpansionModule, MatFormFieldModule, MatSlideToggleModule, MatSortModule} from "@angular/material";
import { AdminloginHeaderComponent } from './layout/adminlogin-header/adminlogin-header.component';
import { AlertBoxComponent } from './components/alert-box/alert-box.component';
import { BlogComponent } from './components/blog/blog.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { BlogViewComponent } from './components/blog-view/blog-view.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { WebcamModule } from 'ngx-webcam';
import { WebcamComponent } from './components/webcam/webcam.component';




@NgModule({
  declarations: [HeaderComponent,
    AuthLayoutComponent, CreateCampaignComponent,
    HomeComponent, LoaderDialogComponent, CmpgnAdgroupMobilePreviewComponent,
    CmpgnAdgroupDesktopPreviewComponent, CmpgnAdgroupDisplayPreviewComponent,
    MainLayoutComponent, SidebarComponent, PreloginHeaderComponent, PostloginHearderComponent, 
    OverviewComponent, FaqComponent, CampaignComponent, PackageComponent, SummaryComponent, 
    PaymentComponent, HowItWorksComponent, CustomersComponent, BenefitsComponent, PricingComponent, 
    CampaignSuccessPromtComponent, UpdatePasswordComponent, ForgetPasswordComponent, ProfileComponent, 
    PaymentInfoComponent, BillingHistoryComponent, AddUpdateCarddetailsComponent, DisplayClicksComponent, 
    AdminloginHeaderComponent, AlertBoxComponent, BlogComponent, BlogViewComponent, WebcamComponent],
  exports: [
    HeaderComponent,
    LoaderDialogComponent,
    PreloginHeaderComponent,
    AuthLayoutComponent
  ],
  imports: [
    CommonModule,
    CustomMaterialModule,
    RouterModule,
    // NgxStripeModule.forRoot('pk_test_G5MGfvOgtZ7pbnw5mjhg2NXN00ySx5LV3d'),
    NgxStripeModule.forRoot('pk_live_XauJxAaaeqIchYR2a6fUwZWE00YJw9OfmU'),
    MatGridListModule,
    TextMaskModule,
    MatRadioModule,
    MatSortModule,
    MatExpansionModule,
    MatSlideToggleModule, NgxPaginationModule,MatFormFieldModule,
    ImageCropperModule,WebcamModule
  ],
  providers: [{ provide : LocationStrategy, useClass : HashLocationStrategy }],
  entryComponents: [LoaderDialogComponent, CampaignSuccessPromtComponent,WebcamComponent ]
})
export class SharedModule {
  carddetails: any;
  showLoader : boolean;
  isaddcarddetails: boolean;

}
