import { Injectable } from '@angular/core';
import {ApiService} from '../api/api.service';
import {AuthService} from '../../auth/auth.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private apiService: ApiService,
              private authService: AuthService) { }

  getAllCampaign(): Observable<any> {
    return this.apiService.get(environment.api_version.VERSION_1, 'getAllCampaign');
  }
  getAllCampaignForAdminDashboard(): Observable<any> {
    return this.apiService.get(environment.api_version.VERSION_1, 'getAllCampaignForAdminDashboard');
  }
  fetchBillingDetailsByUserId(): Observable<any> {
    return this.apiService.post(environment.api_version.VERSION_1, 'fetchBillingDetailsByUserId');
  }
}
