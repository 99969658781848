import { Component, OnInit } from "@angular/core";
import { StripeService } from "ngx-stripe";
import { CampaignService } from "../../../core/services/campaign/campaign.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { MessagesData } from "../../../core/data/message-data";
import { LoaderDialogService } from "../../../core/services/loader-dialog/loader-dialog.service";
import { MatDialog } from "@angular/material/dialog";
import { CampaignSuccessPromtComponent } from "../campaign-success-promt/campaign-success-promt.component";

@Component({
  selector: "app-summary",
  templateUrl: "./summary.component.html",
  styleUrls: ["./summary.component.scss"],
})
export class SummaryComponent implements OnInit {
  stripeElementsInfo: any = {};
  campaignCreationAllStepsInfo: any = {};
  public loaderDialogRef: any;

  constructor(
    private stripeService: StripeService,
    public campaignService: CampaignService,
    private toastr: ToastrService,
    private router: Router,
    private loaderDialogService: LoaderDialogService,
    public dailog: MatDialog
  ) {}

  ngOnInit() {
    this.campaignService.hideHeader = false;
    this.campaignService.createCampaignRequest = sessionStorage.getItem("createCampaignRequestData") && JSON.parse(sessionStorage.getItem("createCampaignRequestData"))
    this.campaignService.paymentMethodInfo = sessionStorage.getItem("paymentMethodInfo") &&   JSON.parse(sessionStorage.getItem("paymentMethodInfo"))
    // this.campaignService.paymentMethodInfo = JSON.parse(sessionStorage.getItem("paymentMethodInfo"))
  }

  submitCampaignCreateRequest() {
    
    // const paymentMethodData = {type: 'card'};
    // console.log('Payment method Info sucessfully added >>> ' + JSON.s  tringify(this.cardNumber));
    this.loaderDialogService.closeAll();
    this.loaderDialogService.open(`${MessagesData.PLEASE_WAIT}`);
    // console.log('campaign creation object >>> ' + JSON.stringify(this.campaignService.createCampaignRequest));
    this.campaignCreationAllStepsInfo = {
      step1CampaignForm: {
        campaignStartDate:
          this.campaignService.createCampaignRequest.campaignStartDate,
        campaignEndDate:
          this.campaignService.createCampaignRequest.campaignEndDate,
        campaignDailyBudget:
          this.campaignService.createCampaignRequest.selectedPlanPrice,
        selectedPlansNoOfClicks:
          this.campaignService.createCampaignRequest.selectedPlansNoOfClicks,
        campaignOverallBudget:
          this.campaignService.createCampaignRequest.selectedPlanPrice,
        // campaignOverallBudget : ( this.campaignService.createCampaignRequest.selectedPlanPrice ) + (this.campaignService.createCampaignRequest.selectedPlanPrice * (1/10))
      },
      step2CampaignForm: {
        keywords: this.campaignService.createCampaignRequest.keywords,
      },
      step3CampaignForm: {
        finalADUrl:
          this.campaignService.createCampaignRequest.finalADUrl.includes(
            "http://"
          ) ||
          this.campaignService.createCampaignRequest.finalADUrl.includes(
            "https://"
          )
            ? this.campaignService.createCampaignRequest.finalADUrl
            : "https://" +
              this.campaignService.createCampaignRequest.finalADUrl,
        websiteUrl: this.campaignService.createCampaignRequest.websiteUrl,
        linkedInUrl: this.campaignService.createCampaignRequest.linkedInUrl,
        textADHeadline1:
          this.campaignService.createCampaignRequest.textADHeadline1,
        textADHeadline2:
          this.campaignService.createCampaignRequest.textADHeadline2,
        optionalHeadLine:
          this.campaignService.createCampaignRequest.textADHeadline3 || "",
        description1: this.campaignService.createCampaignRequest.description1,
        description2: this.campaignService.createCampaignRequest.description2,
        imageAssetUrl:
          this.campaignService.createCampaignRequest.displayPicture,
      },
      step4CampaignForm: {
        CustomerName: this.campaignService.createCampaignRequest.customerName,
        CustomerEmail: this.campaignService.createCampaignRequest.customerEmail,
        CusomerMobile:
          this.campaignService.createCampaignRequest.customerContact,
        zipcode:
          this.campaignService.createCampaignRequest.customersZipcode || "",
      },
      paymentInfoPayload: this.campaignService.paymentMethodInfo,
      customerInfoPayload:
        this.campaignService.paymentMethodInfo.billing_details,
    };
    this.campaignService
      .submitCreateCampiagnForm(this.campaignCreationAllStepsInfo)
      .subscribe(
        (result) => {
          if (result && result.data) {
            if (result.data.message == "success") {
              this.loaderDialogService.close();
              this.loaderDialogRef = this.dailog.open(
                CampaignSuccessPromtComponent,
                {
                  height: "auto",
                  width: "50vw",
                  maxWidth: "100vw",
                  minWidth: "300px",
                }
              );

              this.loaderDialogRef.afterClosed().subscribe(
                (data) => {
                  this.loaderDialogService.close();
                }
                // console.log('Dialog output:', data)
              );
              sessionStorage.clear();
            } else {
              this.loaderDialogService.close();
              this.toastr.error(result.data.message);
            }
            // console.log('data after creating a campaign >>> ' + JSON.stringify(result.data));
            // this.router.navigate(['/main/campaign/dashbord']);
          } else {
            this.toastr.error(MessagesData.SOMETHING_WENT_WRONG);
            this.loaderDialogService.close();
          }
        },
        (err) => {
          this.toastr.error(
            (err && err.error && err.error.message) || MessagesData.SERVER_ERROR
          );
          this.loaderDialogService.close();
        }
      );
  }
}
