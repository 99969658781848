export class CreateCampaignRequest {
  id: number;
  campaignGoal: number;
  campaignName: string;
  campaignStartDate: string;
  campaignEndDate: string;
  campaignUrl: string;
  campaignTargetLocations: string;
  campaignLanguages: string;
  campaignDailyBudget: string;
  campaignOverallBudget: string;
  adGroupName: string;
  adGroupKeywords: string;
  keywords: string;
  keywordsMatchType: string;
  finalADUrl: string;
  textADHeadline1: string;
  textADHeadline2: string;
  textADHeadline3: string;
  displayPathUrl: string;
  description1: string;
  description2: string;
  NoOfClicksPerMonth:number;
  CustomerId:any;
  selectedPlanPrice: number;
  selectedPlanDuration: string;
  customerName: string;
  customerEmail: string;
  customerContact: string;
  customersZipcode: string;
  selectedPlansNoOfClicks: number;
  plansDiscountPercent: number;
  selectedPlanMonth: number;
  linkedInUrl: string;
  websiteUrl: string;
  cardNumber: any;
  cardHolderName: any;
  zipcode: any;
  country: any;
  street: any;
  street2: any;
  city: any;
  state: any;
  campaignId: any;
  isQnary: any;
  displayPicture: any;

  init() {
    return new CreateCampaignRequest();
  }
}
