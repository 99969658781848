import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { LoaderDialogService } from "../../../core/services/loader-dialog/loader-dialog.service";
import { Location } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { FormErrorHandlerService } from "../../../core/services/form-error/form-error-handler.service";
import { CampaignService } from "../../../core/services/campaign/campaign.service";
import { MessagesData } from "../../../core/data/message-data";
import { Router } from "@angular/router";
import { Value } from "@angular/cli/commands/config";
import { error } from "util";
import { MatDialogRef, MatSlideToggleChange } from "@angular/material";
import { SharedService } from "src/app/core/services/shared-service/shared.service";
import * as AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import { WebcamImage } from "ngx-webcam";
import { Subject } from "rxjs";
import { WebcamComponent } from "../webcam/webcam.component";
import { Buffer } from "buffer";

@Component({
  selector: "app-campaign",
  templateUrl: "./campaign.component.html",
  styleUrls: ["./campaign.component.scss"],
})
export class CampaignComponent implements OnInit {
  campaignDetails: any;
  campaignForm: FormGroup;
  formErrorHandlerServiceInstance: FormErrorHandlerService;
  keywords = "string";
  headLine1: string;
  headLine2: string;
  optionalHeadLine: string;
  adDescription1: string;
  adDescription2: string;
  finalURL: string;
  maxValue = 30;
  maxValueForDesc = 90;
  image: any;
  industry: any;
  role: any;
  isWebsiteUrl: boolean = false;
  isLinkedInUrl: boolean = true;
  selectedFileToUpload: File = null;
  imageChangedEvent: any = "";
  croppedImage: any = "";
  webcamImage: WebcamImage;
  captureImage: any;
  seconds: any;
  public loaderDialogRef: any;
  private trigger: Subject<void> = new Subject<void>();
  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private loaderDialogService: LoaderDialogService,
    private location: Location,
    private matDialog: MatDialog,
    public campaignService: CampaignService,
    private router: Router,
    private sharedService: SharedService
  ) {}
  windowWidth: number = window.innerWidth;

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.windowWidth = window.innerWidth;
  }

  ngOnDestroy() {
    if (this.campaignService.editButton === true) {
      this.campaignService.createCampaignRequest.keywords = undefined;
      this.campaignService.createCampaignRequest.textADHeadline1 = undefined;
      this.campaignService.createCampaignRequest.textADHeadline2 = undefined;
      this.campaignService.createCampaignRequest.textADHeadline3 = undefined;
      this.campaignService.createCampaignRequest.linkedInUrl = undefined;
      this.campaignService.createCampaignRequest.websiteUrl = undefined;
      this.campaignService.createCampaignRequest.description1 = undefined;
      this.campaignService.createCampaignRequest.description2 = undefined;
      this.campaignService.createCampaignRequest.customerName = undefined;
      this.campaignService.createCampaignRequest.customerEmail = undefined;
      this.campaignService.createCampaignRequest.customerContact = undefined;
      this.campaignService.createCampaignRequest.customersZipcode = undefined;
      this.campaignService.createCampaignRequest.displayPicture = undefined;
      sessionStorage.clear()
    }
    this.campaignService.editButton = false;
  }

  ngOnInit() {
    sessionStorage.clear();
    this.campaignService.hideHeader = false;
    this.getUserDetail();
    if (this.campaignService.editButton === true) {
      if (
        this.campaignService.createCampaignRequest.finalADUrl.includes(
          "linkedin"
        )
      ) {
        this.isLinkedInUrl = true;
        this.isWebsiteUrl = false;
      } else {
        this.isWebsiteUrl = true;
        this.isLinkedInUrl = false;
      }
    }
    this.campaignForm = this.fb.group({
      keywords: new FormControl(
        this.campaignService.createCampaignRequest.keywords || "",
        [Validators.required]
      ),
      headLine1: new FormControl(
        this.campaignService.createCampaignRequest.textADHeadline1 || "",
        [Validators.required]
      ),
      headLine2: new FormControl(
        this.campaignService.createCampaignRequest.textADHeadline2 || "",
        [Validators.required]
      ),
      optionalHeadLine: new FormControl(
        this.campaignService.createCampaignRequest.textADHeadline3 || "",
        [Validators.required]
      ),
      linkedInUrl: new FormControl(
        this.campaignService.createCampaignRequest.linkedInUrl || ""
      ),
      websiteUrl: new FormControl(
        this.campaignService.createCampaignRequest.websiteUrl || ""
      ),
      adDescription1: new FormControl(
        this.campaignService.createCampaignRequest.description1 || "",
        [Validators.required]
      ),
      adDescription2: new FormControl(
        this.campaignService.createCampaignRequest.description2 || "",
        [Validators.required]
      ),
    });

    // this.campaignService.createCampaignRequest.keywords = this.campaignService.createCampaignRequest.keywords === undefined ?
    //   'test' : this.campaignService.createCampaignRequest.keywords;
  }

  // resetFields() {
  //   sessionStorage.removeItem("createCampaignRequestData");

  //   // this.campaignService.createCampaignRequest.keywords = undefined;
  //   // this.campaignService.createCampaignRequest.textADHeadline1 = undefined;
  //   // this.campaignService.createCampaignRequest.textADHeadline2 = undefined;
  //   // this.campaignService.createCampaignRequest.textADHeadline3 = undefined;
  //   // this.campaignService.createCampaignRequest.linkedInUrl = undefined;
  //   // this.campaignService.createCampaignRequest.websiteUrl = undefined;
  //   // this.campaignService.createCampaignRequest.description1 = undefined;
  //   // this.campaignService.createCampaignRequest.description2 = undefined;
  //   // this.campaignService.createCampaignRequest.customerName = undefined;
  //   // this.campaignService.createCampaignRequest.customerEmail = undefined;
  //   // this.campaignService.createCampaignRequest.customerContact = undefined;
  //   // this.campaignService.createCampaignRequest.customersZipcode = undefined;
  //   // this.campaignService.createCampaignRequest.displayPicture = undefined;
  // }

  getUserDetail() {
    this.campaignService.getUserDetail().subscribe(
      (res: any) => {
        // console.log(res);
        if (res) {
          this.role =
            res.role[0] === undefined || res.role[0].role === undefined
              ? ""
              : res.role[0].role;
          this.industry =
            res.industry[0] === undefined ||
            res.industry[0].industry === undefined
              ? ""
              : res.industry[0].industry;
          this.campaignService.createCampaignRequest.keywords =
            this.campaignService.createCampaignRequest.keywords === undefined
              ? res.firstName + " " + res.lastName
              : this.campaignService.createCampaignRequest.keywords;
          // tslint:disable-next-line:max-line-length
          this.campaignService.createCampaignRequest.textADHeadline1 =
            this.campaignService.createCampaignRequest.textADHeadline1 ===
            undefined
              ? this.role
              : this.campaignService.createCampaignRequest.textADHeadline1;
          // tslint:disable-next-line:max-line-length
          this.campaignService.createCampaignRequest.textADHeadline2 =
            this.campaignService.createCampaignRequest.textADHeadline2 ===
            undefined
              ? this.industry
              : this.campaignService.createCampaignRequest.textADHeadline2;
          this.campaignService.createCampaignRequest.customerName =
            this.campaignService.createCampaignRequest.customerName ===
            undefined
              ? res.firstName + " " + res.lastName
              : this.campaignService.createCampaignRequest.customerName;
          this.campaignService.createCampaignRequest.customerEmail =
            this.campaignService.createCampaignRequest.customerEmail ===
            undefined
              ? res.email
              : this.campaignService.createCampaignRequest.customerEmail;
          this.campaignService.createCampaignRequest.customerContact =
            this.campaignService.createCampaignRequest.customerContact ===
            undefined
              ? res.mobile
              : this.campaignService.createCampaignRequest.customerContact;
          this.campaignService.createCampaignRequest.linkedInUrl =
            this.campaignService.createCampaignRequest.linkedInUrl === undefined
              ? "www.linkedin.com/in/yourID"
              : this.campaignService.createCampaignRequest.linkedInUrl;
          this.campaignService.createCampaignRequest.websiteUrl =
            this.campaignService.createCampaignRequest.websiteUrl === undefined
              ? "www.xyz.com"
              : this.campaignService.createCampaignRequest.websiteUrl;
          this.campaignService.isUserHaveCampaign =
            res.isCampaign === 1 ? 1 : 0;
          this.campaignService.createCampaignRequest.finalADUrl =
            this.campaignService.createCampaignRequest.finalADUrl === undefined
              ? this.campaignService.createCampaignRequest.linkedInUrl
              : this.campaignService.createCampaignRequest.finalADUrl;
        }
      },
      (error) => {
        // console.log('got an error' + error);
      }
    );
  }
  checkDescriptionAndHeadlines() {
    let set = new Set();
    // for (let index = 0; index < 2; index++) {
    set.add(
      this.campaignService.createCampaignRequest.textADHeadline1.replace(
        / /g,
        ""
      )
    );
    set.add(
      this.campaignService.createCampaignRequest.textADHeadline2.replace(
        / /g,
        ""
      )
    );
    set.add(
      this.campaignService.createCampaignRequest.textADHeadline3.replace(
        / /g,
        ""
      )
    );
    if (set.size != 3) {
      this.toastr.error(
        "Headlines are identical. Enter a different headline." ||
          MessagesData.SERVER_ERROR
      );

      return true;
    }
    let set2 = new Set();
    set2.add(
      this.campaignService.createCampaignRequest.description1.replace(/ /g, "")
    );
    set2.add(
      this.campaignService.createCampaignRequest.description2.replace(/ /g, "")
    );
    if (set2.size != 2) {
      this.toastr.error(
        "Descriptions are identical. Enter a different Descriptions." ||
          MessagesData.SERVER_ERROR
      );
      return true;
    }
  }
  submitAndSaveCampaignForm(campaignFormValue: any) {
    if (this.checkDescriptionAndHeadlines()) {
      return false;
    } else if (
      this.campaignService.createCampaignRequest.websiteUrl === "" &&
      this.campaignService.createCampaignRequest.linkedInUrl === ""
    ) {
      this.toastr.error(
        "Url not found, please update url" || MessagesData.SERVER_ERROR
      );
    } else if (
      !this.campaignService.createCampaignRequest.finalADUrl.includes(".")
    ) {
      this.toastr.error(
        "Url is wrong, please update url" || MessagesData.SERVER_ERROR
      );
    } else {
      sessionStorage.setItem("createCampaignRequestData", JSON.stringify(this.campaignService.createCampaignRequest))
      this.router.navigate(["/package"]);
    }
    // console.log(('campaignFormValue >>> ' + JSON.stringify(campaignFormValue)));
    // console.log(('campaignFormValue >>> ' + JSON.stringify(this.campaignService.createCampaignRequest)));
    // this.campaignService.saveStep1CampaignFormData(campaignFormValue);
    // this.campaignService.submitCreateCampiagnForm(campaignFormValue).subscribe(result => {
    //   if (result && result.data) {
    //   } else {
    //     this.router.navigate(['/main/campaign/dashboard']);
    //     console.log('Unknown error');
    //     this.toastr.error(MessagesData.SOMETHING_WENT_WRONG);
    //   }
    // }, err => {
    //   this.toastr.error(err && err.error && err.error.message || MessagesData.SERVER_ERROR);
    // });
  }

  updateFinalUrl(value: any) {
    // if(value !== '')
    //     this.campaignService.createCampaignRequest.finalADUrl = value;
    // else if(this.campaignService.createCampaignRequest.linkedInUrl !== '')
    //   this.campaignService.createCampaignRequest.finalADUrl = this.campaignService.createCampaignRequest.linkedInUrl;
    // else
    //   this.campaignService.createCampaignRequest.finalADUrl = this.campaignService.createCampaignRequest.websiteUrl;
    if (this.isWebsiteUrl) {
      this.campaignService.createCampaignRequest.finalADUrl =
        this.campaignService.createCampaignRequest.websiteUrl;
    } else {
      this.campaignService.createCampaignRequest.finalADUrl =
        this.campaignService.createCampaignRequest.linkedInUrl;
    }
  }
  close() {
    // this.dialogRef.closeAll();
    this.loaderDialogService.close();
  }
  editCampaign(value: any) {
    if (this.checkDescriptionAndHeadlines()) {
      return false;
    }
    value.campaignId = this.campaignService.createCampaignRequest.campaignId;
    value.finalADUrl =
      this.campaignService.createCampaignRequest.finalADUrl.includes(
        "http://"
      ) ||
      this.campaignService.createCampaignRequest.finalADUrl.includes("https://")
        ? this.campaignService.createCampaignRequest.finalADUrl
        : "https://" + this.campaignService.createCampaignRequest.finalADUrl;
    // console.log(value);
    // this.loaderDialogService.closeAll();
    this.loaderDialogService.changeMessage("Please wait. Updating campaigns");
    this.campaignService.updateCampaignDetails(value).subscribe(
      (res: any) => {
        if (res.message == "Success") {
          this.toastr.success("Successfully Updated");
          this.router.navigate(["main/campaign/dashboard"]);
          this.loaderDialogService.close();
        } else {
          this.toastr.error(
            res.message || "The URL is invalid." || MessagesData.SERVER_ERROR
          );
          this.loaderDialogService.close();
        }
      },
      (err) => {
        !(err && err.error && err.error.message == MessagesData.SESSION_TOKEN_EXPIRED_MESSAGE) && this.toastr.error(
          (err.error && err.error.message) || MessagesData.SERVER_ERROR
        );
        // console.log('error for getAllCamoaigns :::  ' + err);
        this.loaderDialogService.close();
      }
    );
  }

  updateUrl(event: any) {
    // console.log(event.checked);
  }

  updateLinkedInUrl($event: MatSlideToggleChange) {
    if ($event.checked === true) {
      this.isWebsiteUrl = false;
      this.isLinkedInUrl = true;
      this.campaignService.createCampaignRequest.finalADUrl =
        this.campaignService.createCampaignRequest.linkedInUrl;
    } else {
      this.isWebsiteUrl = true;
      this.isLinkedInUrl = false;
      this.campaignService.createCampaignRequest.finalADUrl =
        this.campaignService.createCampaignRequest.websiteUrl;
    }
  }

  updateWebsiteUrl($event: MatSlideToggleChange) {
    if ($event.checked === true) {
      this.isWebsiteUrl = true;
      this.isLinkedInUrl = false;
      this.campaignService.createCampaignRequest.finalADUrl =
        this.campaignService.createCampaignRequest.websiteUrl;
    } else {
      this.isWebsiteUrl = false;
      this.isLinkedInUrl = true;
      this.campaignService.createCampaignRequest.finalADUrl =
        this.campaignService.createCampaignRequest.linkedInUrl;
    }
  }

  omit_special_char(event) {
    var k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }

  async onSelectFile(event) {
    var reader = new FileReader();
    const target = event.target;
    reader.readAsDataURL(target.files[0]);
    this.loaderDialogService.closeAll();
    this.campaignService.createCampaignRequest.displayPicture = "";
    this.loaderDialogService.open(
      `${MessagesData.PLEASE_WAIT}. Uploading photo`
    );
    if (
      target.files[0].type === "image/jpeg" ||
      target.files[0].type === "image/png" ||
      target.files[0].type === "image/jpg"
    ) {
      // console.log("image size", target.files[0]);
      let file = target.files[0];
      if (event.target.files && event.target.files[0]) {
        const config = {
          file: file,
          maxSize: 300,
        };
        const resizedImage: any = await this.resizeImage(config);
        // console.log(resizedImage);
        this.sharedService.getS3Session().subscribe(async (response: any) => {
          if (response) {
            const contentType = file.type;
            const s3 = new AWS.S3({
              accessKeyId: response.accessKeyId,
              secretAccessKey: response.secretAccessKey,
              sessionToken: response.sessionToken,
              region: response.region,
            });
            let bufferData = Buffer.from(
              resizedImage.replace(/^data:image\/\w+;base64,/, ""),
              "base64"
            );
            const params = {
              Bucket: response.bucketName,
              Key: "production/" + uuidv4(),
              Body: bufferData,
              ContentType: contentType,
            };
            // console.log(params);
            try {
              const uploadData = await s3.upload(params).promise();
              this.campaignService.createCampaignRequest.displayPicture =
                uploadData.Location;
              this.loaderDialogService.close();
            } catch (error) {
              // console.log("File uploaded unsuccessfully.", error);
              this.toastr.error("Something went wrong", "Failed");
              this.campaignService.createCampaignRequest.displayPicture = "";
              this.loaderDialogService.close();
            }
          } else {
            this.toastr.error("Something went wrong", "Failed");
          }
        });
      } else {
        this.toastr.error("invalid image", "Failed");
      }
    } else {
      this.loaderDialogService.close();
      this.toastr.error("invalid image", "Failed");
    }
  }

  openDialog() {
    this.loaderDialogService.close();
    this.loaderDialogRef = this.matDialog.open(WebcamComponent, {
      height: "auto",
      width: "auto",
      maxWidth: "90vw",
    });

    this.loaderDialogRef.afterClosed().subscribe((result) => {
      // console.log(`Dialog result: ${result}`);
    });
  }

  resizeImage = function (settings) {
    var file = settings.file;
    var targetSize = settings.maxSize;
    var reader = new FileReader();
    var image = new Image();
    //var canvas = document.createElement('canvas');
    var resize = function () {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d")!;

      const maxSize = Math.max(image.width, image.height);
      const scaleFactor = targetSize / maxSize;
      const newWidth = Math.round(image.width * scaleFactor);
      const newHeight = Math.round(image.height * scaleFactor);

      canvas.width = targetSize;
      canvas.height = targetSize;

      const xOffset = (canvas.width - newWidth) / 2;
      const yOffset = (canvas.height - newHeight) / 2;

      ctx.drawImage(image, xOffset, yOffset, newWidth, newHeight);

      const resizedImageDataUrl = canvas.toDataURL("image/jpeg"); // Use 'image/png' for PNG format

      // You can now upload the 'resizedImageDataUrl' to S3 or perform any other action with it
      // console.log(resizedImageDataUrl);
      return resizedImageDataUrl;
    };
    return new Promise(function (ok, no) {
      if (!file.type.match(/image.*/)) {
        no(new Error("Not an image"));
        return;
      }
      reader.onload = function (readerEvent) {
        // console.log(readerEvent.target);
        image.onload = function () {
          return ok(resize());
        };
        // console.log(image);
        image.src = reader.result.toString();
      };
      reader.readAsDataURL(file);
    });
  };
}
