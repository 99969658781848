import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {CampaignService} from "../../../core/services/campaign/campaign.service";
import {SharedService} from "../../../core/services/shared-service/shared.service";
import {MessagesData} from "../../../core/data/message-data";
import {ToastrService} from 'ngx-toastr';
import {providerDef} from "@angular/compiler/src/view_compiler/provider_compiler";
import {CampaignSuccessPromtComponent} from "../campaign-success-promt/campaign-success-promt.component";
import {LoaderDialogService} from "../../../core/services/loader-dialog/loader-dialog.service";
import {MatDialog} from "@angular/material";
// import {LoaderDialogService} from "../../../core/services/loader-dialog/loader-dialog.service";

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss']
})
export class PackageComponent implements OnInit {
  public loaderDialogRef: any;
  constructor(private router: Router,
              public campaignService: CampaignService,
              private sharedService: SharedService,
              private toastr: ToastrService,
              private loaderDialogService: LoaderDialogService,
              public dailog: MatDialog
              // private loaderDialogService: LoaderDialogService

              ) { }
  selectedPlanPrice: any = {
    price: null
  };
  selectedPlanDuration: string;
  allPlansPricesWithClicks = [{price: 30 , clicks: "10" , month: "month"}, {price: 50 , clicks: "20", month: "month"}, {price: 100, clicks: "50", month: "month"}];
  // allPlansDurationWithDiscounts: any = [{planDuration: "One month " , name: 'one month subscription'}, {planDuration: "3 Months ", discounts: 5 , name: 'three months subscription'}, {planDuration: "6 Months ", discounts: 10, name: 'six months subscription'} ];
  chosenItem = this.allPlansPricesWithClicks[0].price;

  data: any = this.allPlansPricesWithClicks[0].price;
  ngOnInit() {
    this.campaignService.hideHeader=false;
    this.data =   sessionStorage.getItem("createCampaignRequestData") && JSON.parse(sessionStorage.getItem("createCampaignRequestData")).selectedPlanPrice || 30
    
  }
  onSelectedPlanPrice(price: number, i:any) {
    this.data = price;
  }
  onSelectedPlanDuration(duarationPlan: string) {
    this.selectedPlanDuration = duarationPlan;
  }
  getIValue(){
    let sessionselectedPlanPrice = sessionStorage.getItem("createCampaignRequestData") ? JSON.parse(sessionStorage.getItem("createCampaignRequestData")).selectedPlanPrice : null
    return sessionselectedPlanPrice ? sessionselectedPlanPrice == 30 ? 0 : sessionselectedPlanPrice == 50 ? 1 : 2  :0
   
  }
  goToBilling() {
    if (this.data === undefined) {
      this.toastr.error(MessagesData.SELECT_PLAN);
      // tslint:disable-next-line:max-line-length
    }
    // else if ((this.data === 30 || this.data === 50) && (this.selectedPlanDuration === 'three months subscription' || this.selectedPlanDuration === 'six months subscription' || this.selectedPlanDuration === null)) {
    //   this.toastr.error(MessagesData.MISMATCH_PLAN);
    // }
    else {
      this.campaignService.createCampaignRequest.selectedPlanDuration = this.selectedPlanDuration;
      this.campaignService.setCampaignPlanPriceLevels(this.data);
      const oldData = JSON.parse(sessionStorage.getItem("createCampaignRequestData"))
      sessionStorage.setItem("createCampaignRequestData", JSON.stringify({...oldData, ...this.campaignService.createCampaignRequest}))
      // this.campaignService.setCampaignCampaignStartAndEndDates(this.selectedPlanDuration);
      // console.log('campaign creation object >>> ' + JSON.stringify(this.campaignService.createCampaignRequest));
      if(!this.campaignService.isUserHaveCampaign){
        this.router.navigate(['/payment']);
      }
      else{
        this.loaderDialogService.open("Please wait");
        this.campaignService.fetchPaymentmethod().subscribe(result => {
          if (result) {
            // console.log('data after creating a campaign >>> ' + JSON.stringify(result.data));
            // this.router.navigate(['/main/campaign/dashbord']);
            this.campaignService.paymentMethodInfo = JSON.parse(result.paymentMethodInfo)
            sessionStorage.setItem("paymentMethodInfo",JSON.stringify(this.campaignService.paymentMethodInfo))
            this.loaderDialogService.close();
            this.router.navigate(['/summary']);
          } else {
            this.toastr.error(MessagesData.SOMETHING_WENT_WRONG);
            this.loaderDialogService.close();
          }
        }, err => {
          this.loaderDialogService.close();
          !(err && err.error && err.error.message == MessagesData.SESSION_TOKEN_EXPIRED_MESSAGE) && this.toastr.error(err && err.error && err.error.message || MessagesData.SERVER_ERROR);
        });

      }
    }
  }


  // isDisabled() {
  //   if (this.data === 30 || this.data === 50) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
}
