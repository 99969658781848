import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Observable, Subject } from "rxjs";
import { WebcamImage, WebcamInitError } from "ngx-webcam";
import { CampaignService } from "src/app/core/services/campaign/campaign.service";
import * as AWS from "aws-sdk";
import { LoaderDialogService } from "src/app/core/services/loader-dialog/loader-dialog.service";
import { SharedService } from "src/app/core/services/shared-service/shared.service";
import { ToastrService } from "ngx-toastr";
import { v4 as uuidv4 } from 'uuid';
import { Buffer } from "buffer";
import { MessagesData } from "src/app/core/data/message-data";

@Component({
  selector: 'app-webcam',
  templateUrl: './webcam.component.html',
  styleUrls: ['./webcam.component.scss'],
})
export class WebcamComponent implements OnInit {
  readonly imageTrigger: Subject<void> = new Subject<void>();
   error?: string;
   public webcamImage!: WebcamImage;
   captureImage  = '';

  constructor(public dialogRef: MatDialogRef<WebcamComponent>,
     public campaignService: CampaignService,
     private sharedService: SharedService,
     private loaderDialogService: LoaderDialogService,
     private toastr: ToastrService,) {}
 
  private trigger: Subject<any> = new Subject();
  private nextWebcam: Subject<any> = new Subject();

  

  ngOnInit() {}
  public triggerSnapshot(): void {
      this.trigger.next();
  }
  public handleImage(webcamImage: WebcamImage): void {
      this.webcamImage = webcamImage;
     // console.log(webcamImage)
      this.captureImage = webcamImage!.imageAsDataUrl;
      this.loaderDialogService.closeAll();
      this.campaignService.createCampaignRequest.displayPicture = '';
      this.loaderDialogService.open(`${MessagesData.PLEASE_WAIT}. Uploading photo`);
    //  console.info('received webcam image', this.captureImage);
      this.sharedService.getS3Session().subscribe(async (response: any) => {
        if (response) {
          const contentType ="image/jpeg";
          const s3 = new AWS.S3({
            accessKeyId: response.accessKeyId,
            secretAccessKey: response.secretAccessKey,
            sessionToken: response.sessionToken,
            region: response.region,

          });
       //   var buf = Buffer.from(this.captureImage,'base64')
          let bufferData = Buffer.from(this.captureImage.replace(/^data:image\/\w+;base64,/, ""),'base64')
          const params = {
            Bucket: response.bucketName,
            Key: 'production/' + uuidv4(),
            Body: bufferData,
            ContentEncoding: 'base64',
            ContentType: contentType,
          };
          // console.log(params);
          try {
           const uploadData = await s3.upload(params).promise();
          //  console.log(uploadData)
           this.campaignService.createCampaignRequest.displayPicture = uploadData.Location;
           this.loaderDialogService.close();
          } catch (error) {
            // console.log('File uploaded unsuccessfully.', error);
            this.toastr.error("Something went wrong", "Failed")
            this.campaignService.createCampaignRequest.displayPicture = '';
            this.loaderDialogService.close();
          }

        } else {
          this.toastr.error("Something went wrong", "Failed")
        }
      })
      this.dialogRef.close();
  }
  public get triggerObservable(): Observable<any> {

      return this.trigger.asObservable();
  }
  public get nextWebcamObservable(): Observable<any> {

      return this.nextWebcam.asObservable();
  }


  

}
