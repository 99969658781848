import { Component, OnInit ,Inject,Renderer2} from "@angular/core";
import { SharedService } from "src/app/core/services/shared-service/shared.service";
import { marked } from "marked";
import { LoaderDialogService } from "src/app/core/services/loader-dialog/loader-dialog.service";
import { Meta, Title } from "@angular/platform-browser";
import { DOCUMENT } from '@angular/common';
// import facebook_logo from "../../../../assets/images/facebook_logo.PricingComponent";
@Component({
  selector: "app-blog-view",
  templateUrl: "./blog-view.component.html",
  styleUrls: ["./blog-view.component.scss"],
})
export class BlogViewComponent implements OnInit {
  markupSchemaUrl :string = '';
  markupSchemaHeadline : string = '';
  markupSchemaPublishedData : string = '';
  markupSchemaBlogImage : string = '';
  blogData: any;
  display: any = false;
  url: string = "";
  author: string = "";
  message: string = "";
  date: string = "";
  categorizedBlogs: any = {};
  blogUrl: any = "";
  titleOnBanner :any = []
  blogDescription :String= ''
  constructor(
    private sharedService: SharedService,
    private loaderDialogService: LoaderDialogService,
    private meta: Meta,
    private title: Title,
    private _renderer2: Renderer2,
     @Inject(DOCUMENT) private _document: Document
  ) {}

  // postImgUrl = [
  //   "assets/images/facebook_logo.png",
  //   "assets/images/twitter_logo.png",
  //   "assets/images/pinterest_logo.png",
  //   "assets/images/mail_logo.png",
  // ];

   ngOnInit() {
    const blogPath = (window.location.pathname).split("/")[2].replace(/-/g, " ")
    this.getBlogsById({
      pathname: blogPath,
      setPath: false,
    });
    this.blogUrl = window.location.href;
    

  }
  loadMarkUpSchema(){
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = 
    `{
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
         "@id": "${this.markupSchemaUrl}"
      },
      "headline": "${this.markupSchemaHeadline}",
       "image": "${this.markupSchemaBlogImage}",  
      "author": {
        "@type": "Organization",
        "name": "character30",
        "url": "https://character30.com"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "character30",
        "logo": {
          "@type": "ImageObject",
         "url": "https://c-30-strapi-image-bucket.s3.amazonaws.com/C30_logo_9a9a7929b2.png"
        }
      },
      "datePublished": "${this.markupSchemaPublishedData}"
    }` ;

    this._renderer2.appendChild(this._document.body, script);
  }
  currentYear = new Date().getFullYear();
  getBlogsById(obj: any) {
    const { pathname, setPath } = obj;
    if (setPath) {
      this.blogUrl =
        window.location.href.split("blog").shift() + `/blog/${pathname.replace(/ /g, "-" )}`;
        window.location.assign(
        window.location.href.split("blog").shift() + `/blog/${pathname.replace(/ /g, "-" )}`
      );
    }
    this.loaderDialogService.open("Please wait.  Fetching article...");
    this.loaderDialogService.changeMessage("Please wait.  Fetching article...");
    this.sharedService.getBlogsByTitle(pathname).subscribe(async (res) => {
      this.blogData = res.data[0];
      this.markupSchemaBlogImage =this.blogData.attributes.FeaturedImage &&
       this.blogData.attributes.FeaturedImage.data && this.blogData.attributes.FeaturedImage.data.attributes && 
       this.blogData.attributes.FeaturedImage.data.attributes.url ? this.blogData.attributes.FeaturedImage.data.attributes.url 
        : this.blogData.attributes.Images && this.blogData.attributes.Images.data
         && this.blogData.attributes.Images.data.length > 0 &&
         this.blogData.attributes.Images.data[0].attributes.url ;
      this.markupSchemaHeadline = this.blogData.attributes.Title;
      this.markupSchemaPublishedData = this.blogData.attributes.publishedAt 
      this.markupSchemaUrl = this.blogUrl;
      this.blogDescription = this.blogData.attributes.BlogDescription
      this.titleOnBanner = this.blogData.attributes.Title.split(":")
      this.loadMarkUpSchema()
      if (!res.data[0]) {
        this.loaderDialogService.close();
        this.display = true;
        return;
      }
      // console.log("blog data ", this.blogData)
      if (
        this.blogData.attributes.SEO &&
        this.blogData.attributes.SEO.length > 0
      ) {
        this.meta.addTags(
          this.blogData.attributes.SEO.map((data) => {
            return {
              name: "description",//data.name
              charset: "utf-8",
              content: data.content,
            };
          }),
          false
        );
      }
      this.title.setTitle(this.blogData.attributes.Title);
      this.categorizedBlogs = await this.getRelatedBlogs(
        this.blogData.attributes.Category || [],
        this.blogData.attributes.Title
      );
          this.categorizedBlogs.data = this.categorizedBlogs.data && this.categorizedBlogs.data.filter(
          (d) => d.id != this.blogData.id
        )  || []
      this.loaderDialogService.close();
      this.display = true;
     
     setTimeout(() => {
      if(document.getElementById("content_data") && res.data && res.data.length && res.data[0].attributes && res.data[0].attributes.Content){
      document.getElementById("content_data").innerHTML = res.data[0].attributes.Content;
    }
     }, 0);
    });
  }
  getRelatedBlogs(category: any, title:string) {
    return new Promise((resolve, reject) => {
      try {
        const filterCondition = category.reduce((pre, next) => {
          return `${pre}filters[Category][$contains]=${next}&`;
        }, "");

        if ( filterCondition && filterCondition != '') {
          this.sharedService
          .getBlogsByCategory(filterCondition, 3)
          .subscribe((res) => {
            if (res.data && res.data.length <= 3) {
              this.sharedService.getBlogsExceptCurrentBlog(1,3 - res.data.length, title).subscribe(res =>{
                resolve(res)
              })
            }else{
              resolve(res);
            }
          });
        }else{
          this.sharedService.getBlogsExceptCurrentBlog(1,3, title).subscribe(res =>{
            resolve(res)
          })
        }
        
      } catch (error) {
        // console.log("error ", error);
        resolve({data:null});
      }
    });
  }
  markDownToHtml(cont: any) {
    return marked(cont);
  }
  setAuthorImage(image: any) {
    return image
      ? image
      : "https://c-30-strapi-image-bucket.s3.amazonaws.com/avater_67b9ef5b06.png";
  }
  shareToFacebook(blogLink: any) {}
  getWebSiteUrl(platform: String) {
    switch (platform) {
      case "facebook":
        return (
          "https://www.facebook.com/sharer/sharer.php?u=" +encodeURI(window.location.href)// "https://character30.com/" //
        );
        break;
      case "pinterest":
        return `https://pinterest.com/pin/create/button/?description=${this.blogData.attributes.Title}&url=${encodeURI(window.location.href)}`;
        break;
      case "twitter":
        return `https://twitter.com/intent/tweet?url=${encodeURI(window.location.href)}&text=${this.blogData.attributes.Title}`;
      case "email":
        return `https://mail.google.com/mail/?view=cm&fs=1&tf=1&su=${this.blogData.attributes.Title}&body=${encodeURI('Please click the link to read more about this article \n '+window.location.href)}`
      case 'LinkedIn' :
        //
        return `https://www.linkedin.com/shareArticle/?url=${encodeURI(window.location.href)}`
      // return `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURI(window.location.href)}`
      default:
        break;
    }
  }
}
