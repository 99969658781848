import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../../core/auth/auth.service";
import {QnaryService} from '../../../qnary/qnary.service';
import {Router} from "@angular/router";


@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {

  constructor(public authService: AuthService,
              public qnaryservice:QnaryService,
              public router : Router) { }

  ngOnInit() {
  }

}
