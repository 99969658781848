import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormControlName,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators
} from '@angular/forms';
import {MessagesData, TemplateFieldConstants} from '../../../core/data/message-data';
import {SharedModule} from '../../shared.module'
import {LoaderDialogService} from '../../../core/services/loader-dialog/loader-dialog.service';
import {keywordMatchTypes} from '../../../core/data/mat-dropdown-data';
import {FormErrorHandlerService} from '../../../core/services/form-error/form-error-handler.service';
import {Elements, ElementsOptions, StripeService} from 'ngx-stripe';
import {Element as StripeElement} from 'ngx-stripe/lib/interfaces/element';
import {CampaignService} from '../../../core/services/campaign/campaign.service';
import {AuthService} from '../../../core/auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
import {Location} from '@angular/common';
import {SharedService} from '../../../core/services/shared-service/shared.service';
import {ErrorStateMatcher} from '@angular/material/core';
import {isNumber} from "util";

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-update-carddetails',
  templateUrl: './add-update-carddetails.component.html',
  styleUrls: ['./add-update-carddetails.component.scss']
})
export class AddUpdateCarddetailsComponent implements OnInit {
  formFieldConstants = TemplateFieldConstants;
  UpdatedetailsForm: FormGroup;
  public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
  paymentForm: FormGroup;
  // campaignGoals = campaignGoals;
  keywordsMatchTypes = keywordMatchTypes;
  formErrorHandlerServiceInstance: FormErrorHandlerService;
  selectedFormStep = 0;
  loaderDialogRef: any;
  elements: Elements;
  card: StripeElement;
  cardNumber: StripeElement;
  cardExpiration: StripeElement;
  cardCvc: StripeElement;
  elementsOptions: ElementsOptions = {
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css?family=Quicksand',
      },
    ],
    locale: 'auto',
  };
  private cardElements: StripeElement[];
  form: any;
  error: any;
  name: any;
  stripeElementsInfo: any = {};
  stripeElementResponseAfterValidationOfGivenInput: any;
  validUrl = require('valid-url');
  paymentMethodAndCustomerInfo: any = {};
  next = 1;
  campaignId = 0;
  campaignCreationAllStepsInfo: any = {};
  private datta: any;
  private cardDetails: any;

  showLoader: boolean;
  private isaddcard: boolean;
  expiryMonthAndYear: any;
  cvv: any;

  expiryYearOrMonthFormControl = new FormControl('', [
    Validators.required,
    Validators.min(4),
    Validators.minLength(5),
    Validators.nullValidator,
    Validators.pattern("^[0-9/]*$" )
  ]);

  matcher = new MyErrorStateMatcher();
  private isDateValid: boolean;
  constructor(
    private SharedModule:SharedModule,
    private fb: FormBuilder,
    private formErrorHandlerService: FormErrorHandlerService,
    private campaignService: CampaignService,
    private sharedService: SharedService,
    private router: Router,
    private activeRouter: ActivatedRoute,
    private matDialog: MatDialog,
    private stripeService: StripeService,
    private toastr: ToastrService,
    private loaderDialogService: LoaderDialogService,
    private location: Location) { }

  ngOnInit() {
    // this.UpdatedetailsForm = this.fb.group({
    //   Creditcard: new FormControl('', Validators.required),
    //   ExpDate: new FormControl('', [Validators.required]),
    //   CVV: new FormControl('', [Validators.required])
    // });
    this.paymentForm = this.fb.group({
      cardHolderName : new FormControl(this.campaignService.createCampaignRequest.cardHolderName ),
      street:new FormControl(this.campaignService.createCampaignRequest.street),
      street2:new FormControl(this.campaignService.createCampaignRequest.street2),
      city:new FormControl(this.campaignService.createCampaignRequest.city),
      state:new FormControl(this.campaignService.createCampaignRequest.state),
      country:new FormControl(this.campaignService.createCampaignRequest.country, [
        Validators.maxLength(2),
        Validators.pattern('^[a-zA-Z ]*$')
      ]),
      zipcode:new FormControl(this.campaignService.createCampaignRequest.zipcode)
      // cardNumber: new FormControl(this.campaignService.createCampaignRequest.cardNumber, [Validators.required]),
    });
    this.isaddcard=this.SharedModule.isaddcarddetails;
    this.mountStripeElements();
    this.campaignService.hideHeader=false;
    this.isDateValid = true;
    this.initializeCardDetials();

  }
updatedetails(){
    this.loaderDialogService.close();
  }
  private mountStripeElements() {
    this.stripeService.elements(this.elementsOptions)
      .subscribe(elements => {
        this.elements = elements;
        if (!this.card) {
          this.cardNumber = this.elements.create('cardNumber', {
            style: {
              base: {
                color: '#505050',
                fontWeight: 500,
                fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
                fontSize: '13px',
                fontSmoothing: 'antialiased',

                ':focus': {
                  color: '#424770',
                },

                '::placeholder': {
                  color: '#7d92bb',
                },
              },
              invalid: {
                color: '#FA755A',
                ':focus': {
                  color: '#FA755A',
                },
                '::placeholder': {
                  color: '#FFCCA5',
                },
              },
            },
            // placeholder: 'Card Number'
          });
          this.cardNumber.mount('#cardNumberRef');
          this.cardNumber.on('change', (event) => {
            // console.log('validation object >>> ' + JSON.stringify(event));
            if (event.error) {
              this.stripeElementResponseAfterValidationOfGivenInput = event.error.message;
              this.stripeElementsInfo.cardNumber = {};
              this.stripeElementsInfo.cardNumber.isValid = false;
              this.stripeElementsInfo.cardNumber.errorMessage = event.error && event.error.message ? event.error.message : 'Unkown stripe error';
            } else if (event.complete) {
              this.stripeElementsInfo.cardNumber = {};
              this.stripeElementsInfo.cardNumber.isValid = true;
              // console.log(this.stripeElementsInfo.cardNumber);
            }
          });
          this.cardExpiration = this.elements.create('cardExpiry', {
            style: {
              base: {
                color: '#505050',
                fontWeight: 500,
                fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
                fontSize: '13px',
                fontSmoothing: 'antialiased',

                ':focus': {
                  color: '#424770',
                },

                '::placeholder': {
                  // color: '#9BACC8',
                  color: '#7d92bb',
                },
              },
              invalid: {
                color: '#FA755A',
                ':focus'
                  : {
                  color: '#FA755A',
                },
                '::placeholder': {
                  color: '#FFCCA5',
                },
              },
            },
            // placeholder: ''
            // placeholder: 'Card Expiration'
          });
          this.cardExpiration.mount('#cardExpiryRef');
          this.cardExpiration.on('change', (event) => {
            // console.log('validation object >>> ' + JSON.stringify(event));
            if (event.error) {
              this.stripeElementResponseAfterValidationOfGivenInput = event.error.message;
              this.stripeElementsInfo.cardExpiry = {};
              this.stripeElementsInfo.cardExpiry.isValid = false;
              this.stripeElementsInfo.cardExpiry.errorMessage = event.error && event.error.message ? event.error.message : 'Unkown stripe error';
            } else if (event.complete) {
              this.stripeElementsInfo.cardExpiry = {};
              this.stripeElementsInfo.cardExpiry.isValid = true;
            }
          });
          this.cardCvc = this.elements.create('cardCvc', {
            style: {
              base: {
                color: '#505050',
                fontWeight: 500,
                fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
                fontSize: '13px',
                fontSmoothing: 'antialiased',

                ':focus': {
                  color: '#424770',
                },

                '::placeholder': {
                  color: '#7d92bb',
                },
              },
              invalid: {
                color: '#FA755A',
                ':focus': {
                  color: '#FA755A',
                },
                '::placeholder': {
                  color: '#FFCCA5',
                },
              },
            }
          });
          this.cardCvc.mount('#cardCvcRef');
          this.cardCvc.on('change', (event) => {
            // console.log('validation object >>> ' + JSON.stringify(event));
            if (event.error) {
              this.stripeElementResponseAfterValidationOfGivenInput = '';
              this.stripeElementsInfo.cardCvc = {};
              this.stripeElementsInfo.cardCvc.isValid = false;
              this.stripeElementsInfo.cardCvc.errorMessage = event.error && event.error.message ? event.error.message : 'Unkown stripe error';
            } else if (event.complete) {
              this.stripeElementsInfo.cardCvc = {};
              this.stripeElementsInfo.cardCvc.isValid = true;
            }
          });
        }
      });
    this.cardElements = [this.cardNumber, this.cardExpiration, this.cardCvc];
  }

  addcarddetails() {
    var customerDetails = {
      cardHolderName : this.campaignService.createCampaignRequest.cardHolderName,
      street:this.campaignService.createCampaignRequest.street,
      street2:this.campaignService.createCampaignRequest.street2,
      city:this.campaignService.createCampaignRequest.city,
      state:this.campaignService.createCampaignRequest.state,
      country:this.campaignService.createCampaignRequest.country,
      zipcode:this.campaignService.createCampaignRequest.zipcode

    }
    this.loaderDialogService.changeMessage('Please wait. Creating Card Details');
    this.stripeService.createPaymentMethod('card', this.cardNumber).subscribe(paymentMethodInfo => {
      if (paymentMethodInfo.paymentMethod) {
        // console.log('Payment method Info sucessfully added >>> ' + JSON.stringify(paymentMethodInfo.paymentMethod));
        this.cardDetails = {
          paymentInfoPayload: paymentMethodInfo.paymentMethod,
          customerInfoPayload: paymentMethodInfo.paymentMethod.billing_details,
          customerDetails:customerDetails
        }
        //console.log(this.cardDetails)
        if (this.stripeElementsInfo && this.stripeElementsInfo.cardNumber && this.stripeElementsInfo.cardExpiry
          && this.stripeElementsInfo.cardCvc && this.stripeElementsInfo.cardNumber.isValid && this.stripeElementsInfo.cardExpiry.isValid
          && this.stripeElementsInfo.cardExpiry.isValid) {
          //api call
          this.sharedService.addcarddetails(this.cardDetails).subscribe(

            res => {

              // console.log(res);
              if (res.message=="Success" ) {
               // console.log("result",res);
                this.sharedService.unset();
                this.router.navigate(['/payment-info']);
              } else {
                this.toastr.error(res.message);
              }
              this.loaderDialogService.close();
            },
            err => {
              this.loaderDialogService.close();
              // console.log(err);
              !(err && err.error && err.error.message == MessagesData.SESSION_TOKEN_EXPIRED_MESSAGE) && this.toastr.error(err && err.error && err.error.message || MessagesData.SERVER_ERROR);
            }
          );


        } else {
          this.toastr.error('Unable to add card details');
        }
      } else if (paymentMethodInfo.error) {
        // console.log('Error in creating payment method >>> ' + JSON.stringify(paymentMethodInfo.error));
        // this.campaignCre   ationAllStepsInfo.paymentInfoPayload = paymentMethodInfo.paymentMethod;

        this.toastr.error('Error in creating the payment method', 'Error');
        this.loaderDialogService.close();
      }
    });
  }

  updateCard() {
    this.loaderDialogService.changeMessage('Please wait. Updating Card Details');
    var data = this.expiryMonthAndYear
    var customerDetails = {
      cardHolderName : this.campaignService.createCampaignRequest.cardHolderName,
      street:this.campaignService.createCampaignRequest.street,
      street2:this.campaignService.createCampaignRequest.street2,
      city:this.campaignService.createCampaignRequest.city,
      state:this.campaignService.createCampaignRequest.state,
      country:this.campaignService.createCampaignRequest.country,
      zipcode:this.campaignService.createCampaignRequest.zipcode

    }
    var cardData={
      expiryMonth : data.split("/")[0],
      expiryYear : 20+data.split("/")[1],
      cardDetails : this.sharedService.carddetails.paymentMethodId,
      customerDetails: customerDetails
  }
  // console.log(cardData)
    this.sharedService.updatecarddetails(cardData).subscribe(
      res => {

        // console.log(res);
        if (res.message=="Success" ) {
          // console.log("result",res);
          this.toastr.success("Updated Successfully");
          this.router.navigate(['/payment-info']);

        } else {
          this.toastr.error(res.message.toString().split(';')[0]);
        }
        this.loaderDialogService.close();
      },
      err => {
        this.loaderDialogService.close();
        // console.log(err);
        !(err && err.error && err.error.message == MessagesData.SESSION_TOKEN_EXPIRED_MESSAGE) && this.toastr.error(err && err.error && err.error.message || MessagesData.SERVER_ERROR);
      }
    );  }
  updateBillingAddress() {
    this.loaderDialogService.changeMessage('Please wait. Updating Card Details');
    var customerDetails = {
      cardHolderName : this.campaignService.createCampaignRequest.cardHolderName,
      street:this.campaignService.createCampaignRequest.street,
      street2:this.campaignService.createCampaignRequest.street2,
      city:this.campaignService.createCampaignRequest.city,
      state:this.campaignService.createCampaignRequest.state,
      country:this.campaignService.createCampaignRequest.country,
      zipcode:this.campaignService.createCampaignRequest.zipcode

    }
    var cardData={
      cardDetails : this.sharedService.carddetails.paymentMethodId,
      customerDetails: customerDetails
    }
    // console.log(cardData)
    this.sharedService.updateBillingAddress(cardData).subscribe(
      res => {

        // console.log(res);
        if (res.message=="Success" ) {
          // console.log("result",res);
          this.toastr.success("Updated Successfully");
          this.router.navigate(['/payment-info']);

        } else {
          this.toastr.error(res.message.toString().split(';')[0]);
        }
        this.loaderDialogService.close();
      },
      err => {
        this.loaderDialogService.close();
        // console.log(err);
        !(err && err.error && err.error.message == MessagesData.SESSION_TOKEN_EXPIRED_MESSAGE) && this.toastr.error(err && err.error && err.error.message || MessagesData.SERVER_ERROR);
      }
    );  }
  validateExpiry (input) {
    // ensure basic format is correct
    if (input.match(/^(0\d|1[0-2])\/\d{2}$/)) {
      const {0: month, 1: year} = input.split("/");

      // get midnight of first day of the next month
      const expiry = new Date(20+year, month);
      const current = new Date();
      if(expiry.getTime() > current.getTime()){
        this.isDateValid = true;
      }
      else{
        this.isDateValid = false;
      }
      return expiry.getTime() > current.getTime();
    } else return false;
  }

  onKey(event) {
    let DobVal = this.expiryMonthAndYear;
    if (DobVal.length === 2 ) {
      this.expiryMonthAndYear=DobVal + '/';
    }
  }

  validateNumber(event) {
    const keyCode = event.keyCode;

    const excludedKeys = [8, 37, 39, 46];

    if (!((keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
    }

  }


  initializeCardDetials(){
    this.campaignService.createCampaignRequest.cardHolderName = this.campaignService.createCampaignRequest.cardHolderName === undefined || this.campaignService.createCampaignRequest.cardHolderName ==''?
      this.sharedService.carddetails.cardHolderName : this.campaignService.createCampaignRequest.cardHolderName;
    this.campaignService.createCampaignRequest.street =this.campaignService.createCampaignRequest.street === undefined || this.campaignService.createCampaignRequest.street ==''?
      this.sharedService.carddetails.street : this.campaignService.createCampaignRequest.street;
    this.campaignService.createCampaignRequest.street2 =this.campaignService.createCampaignRequest.street2 === undefined || this.campaignService.createCampaignRequest.street2 ==''?
      this.sharedService.carddetails.street2 : this.campaignService.createCampaignRequest.street2;
    this.campaignService.createCampaignRequest.city = this.campaignService.createCampaignRequest.city === undefined || this.campaignService.createCampaignRequest.city ==''?
      this.sharedService.carddetails.city : this.campaignService.createCampaignRequest.city;
    this.campaignService.createCampaignRequest.state = this.campaignService.createCampaignRequest.state === undefined || this.campaignService.createCampaignRequest.state ==''?
      this.sharedService.carddetails.state : this.campaignService.createCampaignRequest.state;
    this.campaignService.createCampaignRequest.country = this.campaignService.createCampaignRequest.country === undefined || this.campaignService.createCampaignRequest.country ==''?
      this.sharedService.carddetails.country : this.campaignService.createCampaignRequest.country;
    this.campaignService.createCampaignRequest.zipcode = this.campaignService.createCampaignRequest.zipcode === undefined || this.campaignService.createCampaignRequest.zipcode ==''?
      this.sharedService.carddetails.zipcode : this.campaignService.createCampaignRequest.zipcode;
    this.expiryMonthAndYear = this.expiryMonthAndYear === undefined || this.expiryMonthAndYear === ''?
      ("00"+this.sharedService.carddetails.expiryMonth).slice(-2)+'/'+this.sharedService.carddetails.expiryYear.toString().substr(2,3) : this.expiryMonthAndYear
  }


}
