import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../core/auth/auth.service";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {LoaderDialogService} from "../../../core/services/loader-dialog/loader-dialog.service";
import {FormErrorHandlerService} from "../../../core/services/form-error/form-error-handler.service";
import {SharedService} from "../../../core/services/shared-service/shared.service";
import {MessagesData} from "../../../core/data/message-data";

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {
  updatePasswordForm : FormGroup;
  showSpinner : any;
  usersToken : any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private authService: AuthService,
    private toastr: ToastrService,
    private matDailog: MatDialog,
    private loaderDialogService: LoaderDialogService,
    private formErrorHandlerService: FormErrorHandlerService,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.buildUpdatePasswordForm();
    this.activatedRouter.params.subscribe(params => {
      if (params && params.usersToken) {
        this.usersToken = params.usersToken;
        localStorage.setItem('psem-token', this.usersToken);
      } else {
        this.toastr.error(`${MessagesData.INVALID_CAMPAIGN_ID}`);
      }
    });
  }
  buildUpdatePasswordForm() {
    this.updatePasswordForm = this.fb.group({
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(20), this.checkPassword]),
      confirmPassword: new FormControl('',[Validators.required, Validators.minLength(8), Validators.maxLength(20), this.checkPassword])
    })
  }
  //
  checkPassword(control) {
    const enteredPassword = control.value;
    const passwordCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return (!passwordCheck.test(enteredPassword) && enteredPassword) ? {requirements: true} : null;
  }



  getErrorPassword() {
    return this.updatePasswordForm.get('password').hasError('required') ? 'Field is required (at least eight characters, one uppercase letter and one number)' :
      this.updatePasswordForm.get('password').hasError('requirements') ? 'Password needs to be at least eight characters, one uppercase letter and one number' : '';
  }

  onSubmit(updatePasswordFormValue) {
    this.loaderDialogService.closeAll();
    // console.log(updatePasswordFormValue);
    updatePasswordFormValue.usersToken = this.usersToken;
    if(updatePasswordFormValue.newPassword === updatePasswordFormValue.confirmPassword) {
      this.loaderDialogService.open(`${MessagesData.PLEASE_WAIT}`);
      this.authService.updatepassword(updatePasswordFormValue).subscribe(res => {
          if (res) {
            this.loaderDialogService.close();
            this.toastr.success("Password changed sucessfully...");
          } else {
            this.loaderDialogService.close();
            this.toastr.error("Please try again...");
          }
        },
        err => {
          this.loaderDialogService.close();
          // console.log(err);
          this.toastr.error(err && err.error && err.error.message || MessagesData.SERVER_ERROR);
        });
    }
    else{
      this.toastr.error("Both passwords are unmatched");
    }
  }
}
