import 'hammerjs';
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

const noFunction = () => { };
if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = noFunction;
    window.console.warn = noFunction;
    window.console.error = noFunction;
    window.console.time = noFunction;
    window.console.timeEnd = noFunction;
  }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
