import { Component, OnInit } from '@angular/core';
import {LoaderDialogService} from '../../../core/services/loader-dialog/loader-dialog.service';
import {MatDialogRef} from '@angular/material';
import {MessagesData} from '../../../core/data/message-data';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {SharedService} from '../../../core/services/shared-service/shared.service';
import {CampaignService} from '../../../core/services/campaign/campaign.service';

@Component({
  selector: 'app-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.scss']
})
export class AlertBoxComponent implements OnInit {
  public loaderDialogRef: any;


  constructor(
             public toastr: ToastrService ,private campaignService: CampaignService,
              private sharedService : SharedService,private router: Router,private loaderDialogService: LoaderDialogService) { }

  ngOnInit() {
  }
  cancel(){
    this.sharedService.alert=false;
    this.sharedService.isPaymentDelete=false;
    this.loaderDialogService.close();
  }

  delete() {
    if(this.sharedService.isPaymentDelete==true){
      this.sharedService.alert=true;
      this.loaderDialogService.close()
    }
    else if(this.campaignService.isQnaryCampaign === 1){
      this.loaderDialogService.changeMessage('Please wait. Deleting Campaigns');
      this.campaignService.isQnaryCampaign = 0;
      this.sharedService.deleteQnaryCampaignDetails(this.campaignService.deleteCampaignId, this.campaignService.isQnary).subscribe(
        res => {

          // console.log(res);
          if (res.status == "delete success") {
            // console.log("result",res);
            this.toastr.success("Deleted");
            this.loaderDialogService.close();
            // this.router.navigate(['main/campaign/dashboard']);

          } else {
            this.toastr.error("Could not delete Campaign");
            this.loaderDialogService.close();
          }
        },
        err => {
          this.loaderDialogService.close();
          // console.log(err);
          !(err && err.error && err.error.message == MessagesData.SESSION_TOKEN_EXPIRED_MESSAGE) && this.toastr.error(err && err.error && err.error.message || MessagesData.SERVER_ERROR);
        }

      );
      this.campaignService.isQnary = 0;
    }
    // this.loaderDialogService.closeAll();
    else {
      this.loaderDialogService.changeMessage('Please wait. Deleting Campaigns');
      //this.SharedModule.deletedetails=element.paymentMethodId;
      this.sharedService.deleteCampaignDetails(this.campaignService.deleteCampaignId).subscribe(
        res => {

          // console.log(res);
          if (res.status == "delete success") {
            // console.log("result",res);
            this.toastr.success("Deleted");
            this.loaderDialogService.close();
            // this.router.navigate(['main/campaign/dashboard']);

          } else {
            this.toastr.error("Could not delete Campaign");
            this.loaderDialogService.close();
          }
        },
        err => {
          this.loaderDialogService.close();
          // console.log(err);
          !(err && err.error && err.error.message == MessagesData.SESSION_TOKEN_EXPIRED_MESSAGE) && this.toastr.error(err && err.error && err.error.message || MessagesData.SERVER_ERROR);
        }
      );
    }
  }
}
