import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material';
import {CampaignDashboardTableHeaders} from '../../../core/data/mat-table-data';
import {TemplateFieldConstants} from '../../../core/data/message-data';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material';

import {AdminService} from '../../../core/services/admin/admin.service';
import {ActivatedRoute, Router} from "@angular/router";
import {CampaignService} from '../../../core/services/campaign/campaign.service';
import {timeout} from "rxjs/operators";

// @ts-ignore
@Component({
  selector: 'app-billing-history',
  templateUrl: './billing-history.component.html',
  styleUrls: ['./billing-history.component.scss']
})
export class BillingHistoryComponent implements OnInit {
  displayedColumns: string[] = ['index', 'Date', 'Amount', 'Description'];
  allUserCampaigns: any = [];
  pagesize = 10;
  i = 0;
  dataSource = new MatTableDataSource<any>(this.allUserCampaigns);
  formFieldConstants = TemplateFieldConstants;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  private isPrint: boolean = false;


  constructor(private adminService: AdminService, private activatedRoute: ActivatedRoute, private campaignService: CampaignService,
              private router: Router) {
  }

  ngOnInit() {
    this.campaignService.hideHeader = true;
    this.getBillingDetails();
  }

  getBillingDetails() {
    // console.log("running");
    this.adminService.fetchBillingDetailsByUserId().subscribe(
      (res: any) => {

        if (res && Array.isArray(res) && res.length > 0) {
          this.allUserCampaigns = res;
          this.paginator._changePageSize(this.pagesize);

          this.dataSource.data = this.allUserCampaigns
          this.dataSource.paginator = this.paginator;
          // console.log(this.dataSource)

        } else {
          this.allUserCampaigns = [];
          // console.log(this.allUserCampaigns)
        }

        // this.dataSource.paginator = this.paginator;

      }, error => {
        // console.log('got an error' + error);
      }
    );
  }


  PrintRecord() {
    var divToPrint = '<span ><img class="imageClass1" src="assets/images/logo-new.png" alt = "assets/images/logo-new.png" /></span>' +
      '<span class="billingHeader">Billing History Report</span>' + document.getElementById("tablerecords").innerHTML + '' +
      '<span class = "footer">www.character30.com</span>';
    let newWin = window.open('');
    newWin.document.write(
      '<html><head>' +
      '<title>Character30</title>' +
      '<style>' +
      '.title {\n' +
      '    margin-left: 20px;\n' +
      '    text-align: left;\n' +
      '  }' +
      '.dashboard-wrap {\n' +
      '     MARGIN-LEFT: 1%;\n' +
      '     margin-top: 1%;' +
      '}' +
      '.dashboard-table {\n' +
      '    text-align: left;\n' +
      '    box-shadow: none;\n' +
      '    overflow-x:auto;\n' +
      '    width:100%;\n' +
      '    position:relative;\n' +
      '    top:-60px;' +
      '}' +
      '.campaign-table {\n' +
      '      width: 100%;\n' +
      '      background-color: transparent;\n' +
      '      position: relative;' +
      '}' +
      ' th {\n' +
      '        color: #6c757d;\n' +
      '        font-size: 16px;\n' +
      '        font-weight: 500;\n' +
      '        width: 20%;\n' +
      '        text-align: left' +
      '}\n' +
      'td {\n' +
      '        padding: 4px 1px;\n' +
      '        text-align: left;\n' +
      'border-bottom-width: 1px;\n' +
      '    border-bottom-style: solid;' +
      'border-bottom-color: rgba(0,0,0,.12);' +
      '}' +
      '.imageClass1{' +
      '    width: 220px;' +
      '    height : 38px;' +
      '    display: flex;\n' +
      '    margin-left: auto;\n' +
      '    margin-right: auto;' +
      '    padding-bottom: 30px;' +
      '}' +
      '.billingHeader{' +
      '    display: grid;\n' +
      '    margin-left: auto;\n' +
      '    margin-right: auto;' +
      '    text-align:center;' +
      '    padding-bottom: 30px;' +
      '}' +
      'span{' +
      '    width:100%;' +
      '    text-align: center;' +
      '}' +
      '.footer{' +
      '   position: fixed;\n' +
      '   left: 0;\n' +
      '   bottom: 0;\n' +
      '   width: 100%;\n' +
      '   color: #133e8e;\n' +
      '   text-align: center;' +
      '}' +

      '</style> </head><body  "> '
      + divToPrint + '</html>'
    );
    newWin.document.close();
    newWin.print();
    setTimeout(() => {
      newWin.close()
    }, 100);
    
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/billing-history']);
    });
    // setTimeout(() => window.location.reload(), 100);
    // window.location.reload()
//     this.isPrint = true
//         // var printWindow = window.open(location.href);
//     setTimeout(() => window.print(), 2000);

    // window.print()
//     var printWindow = window.open(location.href);
//     printWindow.print();

  }
}
