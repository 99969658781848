import { Component, OnInit, Renderer2 ,Inject} from "@angular/core";
import { SharedService } from "src/app/core/services/shared-service/shared.service";
// import {marked} from 'marked';
import { LoaderDialogService } from "src/app/core/services/loader-dialog/loader-dialog.service";
import {PageEvent} from '@angular/material/paginator'
import { Meta,  Title} from "@angular/platform-browser";
import { DOCUMENT } from "@angular/common";
@Component({
  selector: "app-blog",
  templateUrl: "./blog.component.html",
  styleUrls: ["./blog.component.scss"],
})
export class BlogComponent implements OnInit {
  // loaderDialogService : LoaderDialogService;
  blogContent :any = '';
  blogs: any = [1, 2, 3, 4, 5];
  blogData: any;
  page: any=1;
  total: any=0;
  pageSize: any = 5;
  defaultBlogImage :any =  "https://c-30-strapi-image-bucket.s3.amazonaws.com/thumbnail_img_179745_5b7de82802.png?updated_at=2023-02-16T09:38:26.845Z"

  length = 0;
  // pageSize = 10;
  pageIndex:any = 0;
  // pageSizeOptions = [5, 10, 25];
  showFirstLastButtons = true;
  display = false;
  markUpData = []
 ;
  constructor(private sharedService: SharedService,private loaderDialogService : LoaderDialogService, 
    private meta:Meta, private title:Title,   private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document) {}

  ngOnInit() {
    window.location.href = "https://blog.character30.com/";
    // this.loaderDialogService.open("Blogs are loading...")
    // this.title.setTitle("Character30")
    // this.getBlogs( this.page, this.pageSize);
    
    // this.loaderDialogService.closeAll()
  }
  loadMarkupSchema(){
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text =  `${this.markUpData}`
    this._renderer2.appendChild(this._document.body, script);
  }
  
  getBlogs(page: any, pageSize:any) {
    // this.loaderDialogService.closeAll();
    this.loaderDialogService.open("'Please wait.  Fetching all articles...")
    this.loaderDialogService.changeMessage('Please wait.  Fetching all articles...');
    
    this.sharedService.getBlogs(page, pageSize).subscribe((res) => {
      
      this.total = res.meta.pagination.total
      this.length = res.meta.pagination.total
      this.blogs = res.data || [];
      // this.blogs = []
      //improper schema 
      // this.blogs.map((blog) =>{
      //   this.loadMultipleMarkupSchema({markupSchemaUrl:`${window.location.href}/${blog.attributes.Title.replace(/ /g, "+")}`,
      //   markupSchemaHeadline : blog.attributes.Title,
      //   markupSchemaBlogImage :  blog.attributes.Images.data && blog.attributes.Images.data.length > 0 &&
      //   blog.attributes.Images.data[0].attributes.formats
      //   .thumbnail.url  ?  blog.attributes.Images.data[0].attributes.formats.thumbnail.url
      //      :
      //                        this.defaultBlogImage,
      //   markupSchemaPublishedData: blog.attributes.publishedAt 
      // })
      // })
      // this.loadMarkupSchema()
      this.total = res.meta.pagination.total;
      this.loaderDialogService.close()
      this.display = true
    });
  }
  loadMultipleMarkupSchema({markupSchemaUrl, markupSchemaHeadline, markupSchemaBlogImage,  markupSchemaPublishedData}){
    this.markUpData.push({
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
         "@id": `${markupSchemaUrl}`
      },
      "headline": `${markupSchemaHeadline}`,
       "image": `${markupSchemaBlogImage}`,  
      "author": {
        "@type": "Organization",
        "name": "character30",
        "url": "https://character30.com"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "character30",
        "logo": {
          "@type": "ImageObject",
         "url": "https://c-30-strapi-image-bucket.s3.amazonaws.com/C30_logo_9a9a7929b2.png"
        }
      },
      "datePublished": `${markupSchemaPublishedData}`
    })
  }
  getBlogById(id: any) {
  //  localStorage.setItem("blogId", id)
  }
  // markDownToHtml(cont:any){
  // return marked(cont);
  // }
  // public 
  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.page = this.pageIndex+1;
    // if (this.total == this.length) {
    //   // this.showFirstLastButtons = false;
    // }
    this.getBlogs(this.page, this.pageSize)
  }
  setImage(images:any){
    return images.data ? images.data[0].attributes.formats.thumbnail.url  : this.defaultBlogImage
  }
  currentYear = new Date().getFullYear();
  getUrl(title:any) {
    return "/blog/"+title.replace(/ /g, "-" )
  }
}

