import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {ApiService} from '../services/api/api.service';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {CampaignService} from "../services/campaign/campaign.service";

@Injectable({providedIn: 'root'})
export class AuthService {
  token: any;
  expires: any;
  userId: any;
  userName: any;
  userInfo: any;
  isAuthenticated = false;
  public authenticationUpdate: Subject<any> = new Subject<any>();
  isAdminAuthenticated = false;
  isQnaryAuthenticated = false;
  private qnaryToken: string;
  private adminToken: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private apiService: ApiService,
  ) {
    if (localStorage.getItem('psem-token')) {
      this.isAuthenticated = true;
    } else {
      this.isAuthenticated = false;
    }
  }
  admin_login(adminlogin): Observable<any> {
    return this.apiService.post(environment.api_version.BASIC_API, 'admin_login', adminlogin);
  }
  qnary_login(qnarylogin): Observable<any> {
    return this.apiService.post(environment.api_version.BASIC_API, 'qnary_login', qnarylogin);
  }
  login(loginContext): Observable<any> {
    return this.apiService.post(environment.api_version.BASIC_API, 'login', loginContext);
  }

  signup(registerContext) {
    return this.apiService.post(environment.api_version.BASIC_API, 'register', registerContext);
  }

  forgetpassword(forgetPasswordContext) {
    return this.apiService.post(environment.api_version.BASIC_API, 'forgetPassword', forgetPasswordContext);
  }

  updatepassword(updatePasswordContext) {
    return this.apiService.post(environment.api_version.BASIC_API, 'updatePassword', updatePasswordContext);
  }
  resetPassword(updatePassword): Observable<any>  {
    return this.apiService.post(environment.api_version.BASIC_API, 'reset', updatePassword);
  }

  getToken() {
    return this.token || localStorage.getItem('psem-token');
  }
  getQnaryToken() {
    return this.qnaryToken || localStorage.getItem('qnary-token');
  }
  getAdminToken() {
    return this.adminToken || localStorage.getItem('admin-token');
  }

  getTokenExpire() {
    return this.expires;
  }

  setToken(): void {
    this.token = localStorage.getItem('psem-token');
  }
  setQnaryToken(): void {
    this.qnaryToken = localStorage.getItem('qnary-token');
  }
  setAdminToken(): void {
    this.adminToken = localStorage.getItem('admin-token');
  }

  setTokenExpire() {
    this.expires = localStorage.getItem('tokenExpire');
  }

  isAuthenticate(): boolean {
    if (localStorage.getItem('psem-token')) {
      this.token = localStorage.getItem('psem-token');
      this.isAuthenticated = true;
      return true;
    } else {
      this.isAuthenticated = false;
      return false;
    }
  }
  isQnaryAuthenticate(): boolean {
    if (localStorage.getItem('qnary-token')) {
      this.qnaryToken = localStorage.getItem('qnary-token');
      this.isQnaryAuthenticated = true;
      return true;
    } else {
      this.isQnaryAuthenticated = false;
      return false;
    }
  }
  isAdminAuthenticate(): boolean {
    if (localStorage.getItem('admin-token')) {
      this.adminToken = localStorage.getItem('admin-token');
      this.isAdminAuthenticated = true;
      return true;
    } else {
      this.isAdminAuthenticated = false;
      return false;
    }
  }

  logoutForMalformedToken(): void {
    this.expires = null;
    if(this.router.url.toString().includes("qnary")){
      localStorage.removeItem("qnary-token");
      this.isQnaryAuthenticated = false
      this.qnaryToken = null;
      this.router.navigate(['qnary/login']);
    }
    else if(this.router.url.toString().includes("admin")){
      localStorage.removeItem("admin-token");
      this.isAdminAuthenticated = false;
      this.adminToken = null;
      this.router.navigate(['admin/login']);
    }
    else{
      localStorage.removeItem("psem-token");
      this.token = null;
      this.isAuthenticated = false;
      // this.campaignService.campaignDetails = ''
      this.router.navigate(['auth/login']);
      // this.router.navigate(['auth/login'])
      //   .then(() => {
      //     window.location.reload();
      //   });

    }
  }

  logOutAfterUpdateProfile(): void {
    localStorage.removeItem("psem-token");;
    this.token = null;
    this.expires = null;
    this.isAuthenticated = false;
    // this.router.navigate(['auth/login']);
  }

  logout(): Observable<any> {
    // this.logoutForMalformedToken();
    return  this.apiService.get(environment.api_version.BASIC_API, 'logout');
  }
}
