import { Component, OnInit } from '@angular/core';
declare var showSlides: any;
import '../../../../assets/js/theme.js';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})

export class OverviewComponent implements OnInit {
  allPlansPricesWithClicks = [{price: 30 , clicks: "10" , month: "month"}, {price: 50 , clicks: "20", month: "month"}, {price: 100, clicks: "50", month: "month"}];
  private fragment: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    showSlides(1)
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
  }
  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) { }
  }
  onSelectedPlanDuration(oneMonthSubscription: string) {

  }
  currentYear = new Date().getFullYear();
}
