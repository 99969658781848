import {Component, Inject, Input, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-loader-dialog',
  templateUrl: './loader-dialog.component.html',
  styleUrls: ['./loader-dialog.component.scss']
})
export class LoaderDialogComponent implements OnInit {

  // @Input() messageContext: any = '';
  textBody: string;

  constructor(private dialogRef: MatDialogRef<LoaderDialogComponent>,@Inject(MAT_DIALOG_DATA) public dialogContext) {
    this.textBody = this.dialogContext.messageContext;
    dialogRef.disableClose = true;
  }

  ngOnInit() {
  }


}
