import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import { CampaignService } from 'src/app/core/services/campaign/campaign.service';

@Component({
  selector: 'app-campaign-success-promt',
  templateUrl: './campaign-success-promt.component.html',
  styleUrls: ['./campaign-success-promt.component.scss']
})
export class CampaignSuccessPromtComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<CampaignSuccessPromtComponent>, private router: Router, public campaignService: CampaignService) { }

  ngOnInit() {
    this.campaignService.createCampaignRequest.keywords = undefined;
    this.campaignService.createCampaignRequest.textADHeadline1 = undefined;
    this.campaignService.createCampaignRequest.textADHeadline2 = undefined;
    this.campaignService.createCampaignRequest.textADHeadline3 = undefined;
    this.campaignService.createCampaignRequest.linkedInUrl = undefined;
    this.campaignService.createCampaignRequest.websiteUrl = undefined;
    this.campaignService.createCampaignRequest.description1 = undefined;
    this.campaignService.createCampaignRequest.description2 = undefined;
    this.campaignService.createCampaignRequest.customerName = undefined;
    this.campaignService.createCampaignRequest.customerEmail = undefined;
    this.campaignService.createCampaignRequest.customerContact = undefined;
    this.campaignService.createCampaignRequest.customersZipcode = undefined;
    this.campaignService.createCampaignRequest.displayPicture = undefined;
    this.campaignService.createCampaignRequest.selectedPlanDuration = undefined;
    this.campaignService.createCampaignRequest.campaignStartDate = undefined;
    this.campaignService.createCampaignRequest.campaignEndDate = undefined;
    this.campaignService.createCampaignRequest.finalADUrl = undefined;
    this.campaignService.createCampaignRequest.plansDiscountPercent = undefined;
    this.campaignService.createCampaignRequest.selectedPlanMonth = undefined;
    this.campaignService.createCampaignRequest.selectedPlanPrice = undefined;
    this.campaignService.createCampaignRequest.selectedPlansNoOfClicks = undefined;
  }

  close() {
    // this.dialogRef.closeAll();
    this.dialogRef.close();
  }

}
