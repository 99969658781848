import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MessagesData, TemplateFieldConstants} from "../../../core/data/message-data";
import {AuthService} from "../../../core/auth/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material";
import {LoaderDialogService} from "../../../core/services/loader-dialog/loader-dialog.service";
import {SharedService} from "../../../core/services/shared-service/shared.service";
import {finalize} from "rxjs/operators";
import {userInfo} from "os";
import {isNotNullOrUndefined} from "codelyzer/util/isNotNullOrUndefined";
import {CampaignService} from "../../../core/services/campaign/campaign.service";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {


  public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
  signUpForm: FormGroup;
  formFieldConstants = TemplateFieldConstants;
  roles : any = [];
  industries: any = [];
  userInfo : any =[];
  titles: any = ['Jr.' , 'Senior' , 'Mr.', 'Ms.' ,'Mrs.'];
  industryData: any;
  roleData : any;
  titleData : any;
  errorDetails:boolean;
  newInsertRole: any;
  newInsertIndustry: any;

  constructor(
    private profile: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private toastr: ToastrService,
    private matDialog: MatDialog,
    private loaderDialogService: LoaderDialogService,
    private sharedService: SharedService,
    private campaignService:CampaignService
  ) {
  }

  ngOnInit() {
    this.getAllRoles();
    this.getAllIndustries();
    this.loaderDialogService.closeAll();
    this.getUserDetails();
    this.buildSignUpForm();
  }

  buildSignUpForm() {
    let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.signUpForm = this.profile.group({
      email: new FormControl(this.userInfo.email, [Validators.required, Validators.email, Validators.pattern(emailregex)]),
      password: new FormControl(this.userInfo.password, [Validators.required, Validators.minLength(8), Validators.maxLength(20), this.checkPassword]),
      firstName: new FormControl(this.userInfo.firstName, [Validators.required]),
      middleName: new FormControl(this.userInfo.middleName),
      lastName: new FormControl(this.userInfo.lastName, [Validators.required]),
      contact: new FormControl(this.userInfo.mobile, [Validators.required]),
      title: new FormControl(''),
      role: new FormControl(''),
      industry: new FormControl('' ),
      userId : new FormControl(this.userInfo.userId),
      newRoleData : new FormControl(''),
      newIndustryData : new FormControl('')
    });
  }

  // ngOnDestroy(){
  //   this.signUpForm['email'] = undefined
  // }    

  checkPassword(control) {
    const enteredPassword = control.value;
    const passwordCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return (!passwordCheck.test(enteredPassword) && enteredPassword) ? {requirements: true} : null;
  }

  getErrorEmail() {
    return this.signUpForm.get('email').hasError('required') ? 'Email is required' :
      this.signUpForm.get('email').hasError('pattern') ? 'Not a valid email address' :
        this.signUpForm.get('email').hasError('email') ? 'Not a valid email address' :
          this.signUpForm.get('email').hasError('alreadyInUse') ? 'This email address is already in use' : '';
  }

  getErrorPassword() {
    return this.signUpForm.get('password').hasError('required') ? 'Field is required (at least eight characters, one uppercase letter and one number)' :
      this.signUpForm.get('password').hasError('requirements') ? 'Password needs to be at least eight characters, one uppercase letter and one number' : '';
  }

  getErrorFirstName() {
    return this.signUpForm.get('firstName').hasError('required') ? 'First name is required' : '';
  }

  getErrormiddleName(){
    return this.signUpForm.get('middleName').hasError('required') ? 'middle name is required' : '';
  }

  getErrorLastName() {
    return this.signUpForm.get('lastName').hasError('required') ? 'Last name is required' : '';
  }

  getErrorContactNo() {
    return this.signUpForm.get('contact').hasError('required') ? 'Contact # is required' : '';
  }
  getErrorRole() {
    return this.signUpForm.get('role').hasError('required') ? 'role should be selected' : '';
  }
  getErrorIndustry() {
    return this.signUpForm.get('industry').hasError('required') ? 'industry should be selected' : '';
  }

  onSubmit(registrationFormValue) {
    // console.log('registration form>>> '+ JSON.stringify(registrationFormValue))
    this.loaderDialogService.closeAll();
    this.loaderDialogService.open(`${MessagesData.PLEASE_WAIT}.   ${MessagesData.UPDATING_ACCOUNT_LOADER}`);
    // console.log('registration form info >>>> ' + JSON.stringify(registrationFormValue));
    if(isNaN(registrationFormValue.role)){
      registrationFormValue.role = isNaN(registrationFormValue.role) ? 0 : registrationFormValue.role;
    }
    else{
      var roles = this.roleData === 'default'? 0 : this.roleData
      registrationFormValue.role = registrationFormValue.role ? registrationFormValue.role : roles;
    }
    if(isNaN(registrationFormValue.industry)){
      registrationFormValue.industry = isNaN(registrationFormValue.industry) ? 0 : registrationFormValue.industry;
    }
    else{
      var indust = this.industryData === 'default'? 0 : this.industryData
      registrationFormValue.industry = registrationFormValue.industry ? registrationFormValue.industry : indust;
    }
    // registrationFormValue.title = isNaN(registrationFormValue.title) ? 0 : registrationFormValue.title;
    registrationFormValue.title = registrationFormValue.title ? registrationFormValue.title : this.titleData;
    // // registrationFormValue.industry = registrationFormValue.industry ? registrationFormValue.industry : 0 ;
    // if(registrationFormValue.email !== this.userInfo.email){
    this.errorDetails = false
    this.sharedService.checkEmail(registrationFormValue).subscribe(res => {
        // console.log('got an exception >>' + JSON.stringify(res));
        this.getUserData(registrationFormValue);
      }, err => {
      this.loaderDialogService.close();
      !(err && err.error && err.error.message == MessagesData.SESSION_TOKEN_EXPIRED_MESSAGE) && this.toastr.error(err && err.error && err.error.message || MessagesData.SERVER_ERROR);
      // console.log('got an exception >>' + JSON.stringify(err));
      }
    )
  }
  // private createLogout(registrationFormValue) {
  //     this.authService.logout().subscribe( res => {
  //       if ( res ) {
  //         // console.log('success' + JSON.stringify(res));
  //         // localStorage.clear();
  //         // this.router.navigate(['/auth/login']);
  //         this.authService.logOutAfterUpdateProfile();
  //         const loginContext = {email: registrationFormValue.email, password: registrationFormValue.password};
  //         this.doLoginAfterRegistration(loginContext);
  //       } else {
  //         // console.log('got an exception'  + JSON.stringify(res));
  //       } } , error => {
  //       // console.log('exce' + JSON.stringify(error))
  //     });
  // }
  getUserData(registrationFormValue: any) {
    this.authService.logout().subscribe( res => {
      if ( res ) {
        // console.log('success' + JSON.stringify(res));
        // localStorage.clear();
        // this.router.navigate(['/auth/login']);
        this.authService.logOutAfterUpdateProfile();
      } else {
        // console.log('got an exception'  + JSON.stringify(res));
      } } , error => {
      // console.log('exce' + JSON.stringify(error))
    });
    this.sharedService.updateProfile(registrationFormValue).pipe(finalize(() => {
      this.loaderDialogService.close();
    })).subscribe(result => {
      if (result) {
        this.toastr.success(result && result.message || MessagesData.UPDATE_PROFILE);
        // this.router.navigate(['/campaign']);
        this.errorDetails = false;
        const loginContext = {email: registrationFormValue.email, password: registrationFormValue.password};
        this.doLoginAfterRegistration(loginContext);
        // this.createLogout(registrationFormValue);

      } else {
        // this.loaderDialogRef.close();
        this.toastr.error(MessagesData.UNKNOWN_ERROR);
      }
    }, error => {
      // this.loaderDialogRef.close();
      // console.log('error info >>> ' + JSON.stringify(error));
      this.errorDetails = true;
      this.toastr.error(error && error.error && error.error.message || MessagesData.SERVER_ERROR);
    });
  }
  doLoginAfterRegistration(loginContext) {
    this.loaderDialogService.changeMessage(`${MessagesData.PLEASE_WAIT}.  ${MessagesData.LOGIN_LOADER}`);
    this.authService.login(loginContext).subscribe(
      res => {
        // console.log(res);
        if (res && res.data) {
          this.loaderDialogService.changeMessage(`${MessagesData.REDIRECTING}`);
          localStorage.setItem('psem-token', res.data.token);
          this.authService.setToken();
          this.campaignService.createCampaignRequest.keywords=undefined
          this.campaignService.createCampaignRequest.textADHeadline1=undefined
          this.campaignService.createCampaignRequest.textADHeadline2=undefined
          this.campaignService.createCampaignRequest.keywords=undefined
          this.router.navigate([
            this.activatedRouter.snapshot.queryParams && this.activatedRouter.snapshot.queryParams.returnUrl || '/campaign']);
          this.loaderDialogService.close();
        } else {
          this.loaderDialogService.close();
          this.toastr.error(MessagesData.LOGIN_FAILED);
        }
      },
      err => {
        this.loaderDialogService.close();
        // console.log(err);
        this.toastr.error(err && err.error && err.error.message || MessagesData.SERVER_ERROR);
      }
    );
  }

  onStrengthChanged($event: number) {
    // console.log($event);
  }

  navigateToLogin() {
    if (this.activatedRouter.snapshot.queryParams && this.activatedRouter.snapshot.queryParams.returnUrl) {
      // console.log(this.activatedRouter.snapshot.queryParams.returnUrl);
      this.router.navigate(['auth/login'], {queryParams: {returnUrl: this.activatedRouter.snapshot.queryParams.returnUrl}});
    } else {
      this.router.navigate(['auth/login']);
    }
  }

  getAllRoles(){
    var userInfo1 = {userId : this.userInfo.userId}
    this.sharedService.getRolesForAUser().subscribe(res => {
        // console.log('got an exception >>' + JSON.stringify(res));
        this.roles = res.data;
      }, err => {
        // console.log('got an exception >>' + JSON.stringify(err));
      }
    )
  }

  getAllIndustries() {
    this.sharedService.getIndustriesForAUser().subscribe(res => {
        // console.log('got an exception >>' + JSON.stringify(res));
        this.industries = res.data;
      }, err => {
        // console.log('got an exception >>' + JSON.stringify(err));
      }
    )
  }
  selectedRole(role: any){
    // tslint:disable-next-line:radix
    this.signUpForm.patchValue({ role : parseInt(role.id)});
  }

  selectedIndustry(industry: any){
    // tslint:disable-next-line:radix
    this.signUpForm.patchValue({ industry : parseInt(industry.id)});
  }

  selectedTitle(title: any) {
    // console.log('title >>> ' + JSON.stringify(title.value));
    this.signUpForm.patchValue({ title : title});
  }
  getUserDetails() {
    this.sharedService.getUserDetails().subscribe(
      (res: any) => {
        // console.log(res);
        if (res) {
          this.userInfo = res
          this.buildSignUpForm()
          this.industryData=(this.userInfo.industry[0] === undefined || this.userInfo.industry[0] === null) ? 'default' : this.userInfo.industry[0].id
          this.roleData=(this.userInfo.role[0] === undefined || this.userInfo.role[0] === null) ? 'default' : this.userInfo.role[0].id
          this.titleData=(this.userInfo.title === undefined || this.userInfo.title === null)? 'default' : this.userInfo.title
        }
      } , error => {
        // console.log('got an error' + error);
      }
    );
  }
}
