import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {AuthService} from '../auth/auth.service';
import {catchError, map, timeout} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {MessagesData} from '../data/message-data';
import {LoaderDialogService} from '../services/loader-dialog/loader-dialog.service';
import {Router} from "@angular/router";

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private loaderDialogService: LoaderDialogService,
    private router: Router,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headerConfig: any = {
      'Content-Type': 'application/json',
    };
    if (this.authService.isAuthenticate() || this.authService.isQnaryAuthenticate() || this.authService.isAdminAuthenticate()) {
      if((this.router.url).includes('qnary') && !(this.router.url).includes('login')){
        headerConfig['x-access-token'] = this.authService.getQnaryToken();
      }
      else if((this.router.url).includes('admin') && !(this.router.url).includes('login')){
        headerConfig['x-access-token'] = this.authService.getAdminToken();
      }
      else if(this.authService.isAuthenticate() && !this.router.url.includes("blog")){
        headerConfig['x-access-token'] = this.authService.getToken();
      }
      else{
        return next.handle(req.clone({setHeaders: headerConfig}));
      }
      const request = req.clone({setHeaders: headerConfig});
      return next.handle(request).pipe(
        timeout(60000),
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // console.log('response for easch reuquest ;; ' + JSON.stringify(event));
            if (event.body && event.body.message && event.body.message.includes(MessagesData.SERVER_WORKING_MESSAGE && !this.router.url.includes("profile"))) {
              // this.toastr.warning(MessagesData.SESSION_TOKEN_EXPIRED);
              // this.authService.logout();
              this.authService.logout().subscribe( res => {
                if ( res ) {
                  // console.log('success' + JSON.stringify(res));
                  this.authService.logoutForMalformedToken();
                } else {
                  // console.log('got an exception'  + JSON.stringify(res));
                } } , error => {
                // console.log('exce' + JSON.stringify(error));
              });
              this.loaderDialogService.close();
              return null;
            }
          }
          return event;
        }),
        catchError((error, caught) => {
          if (error.status === 401 && error.error && error.error.type === 'Not Authorized') {
            this.authService.logoutForMalformedToken();
          } else if (error.status === 419) {
            this.authService.logoutForMalformedToken();
            // this.authService.getRefreshToken();
          } else if (error && error.name === 'TimeoutError') {
            this.toastr.error(MessagesData.REQUEST_TIMEOUT);
            this.loaderDialogService.close();
          } else {  // intercept the response error and displace it to the console
            return throwError(error); // return the error to the method that called it
          }
        })) as any;
    }
    else {
      return next.handle(req.clone({setHeaders: headerConfig}));
    }

  }

}
