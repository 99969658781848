import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../core/auth/auth.service";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {LoaderDialogService} from "../../../core/services/loader-dialog/loader-dialog.service";
import {FormErrorHandlerService} from "../../../core/services/form-error/form-error-handler.service";
import {SharedService} from "../../../core/services/shared-service/shared.service";
import {MessagesData} from "../../../core/data/message-data";

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  forgetPasswordForm : FormGroup;
  showSpinner : any;
  confirmation : boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private authService: AuthService,
    private toastr: ToastrService,
    private matDailog: MatDialog,
    private loaderDialogService: LoaderDialogService,
    private formErrorHandlerService: FormErrorHandlerService,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.buildForgetPasswordForm();
  }

  buildForgetPasswordForm() {
    let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.forgetPasswordForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(emailregex)])
    });
  }

  getErrorEmail() {
    return this.forgetPasswordForm.get('email').hasError('required') ? 'Email is required' :
      this.forgetPasswordForm.get('email').hasError('pattern') ? 'Not a valid email address' :
        this.forgetPasswordForm.get('email').hasError('email') ? 'Not a valid email address' :
          this.forgetPasswordForm.get('email').hasError('alreadyInUse') ? 'This email address is already in use' : '';
  }

  onSubmit(forgetPasswordFormValue) {
    this.loaderDialogService.closeAll();
    // console.log(forgetPasswordFormValue.email);
    this.loaderDialogService.open(`${MessagesData.PLEASE_WAIT}`);
    this.authService.forgetpassword(forgetPasswordFormValue).subscribe(res => {
      if(res) {
        this.loaderDialogService.close();
        this.confirmation = true;
       // this.toastr.success("Email Senting sucessfully for reseting password...");
      }
      else {
        this.loaderDialogService.close();
        this.toastr.error("Please try again...");
      }

    },
      err => {
        this.loaderDialogService.close();
        // console.log(err);
        this.toastr.error(err && err.error && err.error.message || MessagesData.SERVER_ERROR);
      });
  }

}
