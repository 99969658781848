import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  constructor() { }
  allPlansPricesWithClicks = [{price: 30 , clicks: "10" , month: "month"}, {price: 50 , clicks: "20", month: "month"}, {price: 100, clicks: "50", month: "month"}];

  ngOnInit() {
  }
  currentYear = new Date().getFullYear();
  onSelectedPlanDuration(oneMonthSubscription: string) {

  }
}
