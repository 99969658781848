import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../core/auth/auth.service';
import {TemplateFieldConstants} from '../../../core/data/message-data';
import {SharedService} from '../../../core/services/shared-service/shared.service';
import { Router} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  avatarImg = '../../../assets/i18n/avatars/avatar1.png';
  headerTitle = 'Personal SEM';
  authenticated = false;
  formFieldConstants = TemplateFieldConstants;
  sharedServiceInstance: SharedService;

  constructor(
    public authService: AuthService,
    private sharedService: SharedService ,
    private router: Router
    ) {
    this.sharedServiceInstance = this.sharedService;
  }

  ngOnInit() {
    this.authenticated = this.authService.isAuthenticated;
    this.authService.authenticationUpdate.subscribe(isAuthenticated => this.authenticated = isAuthenticated,
      err => {});
        // console.log('Failed to update authentication status'));
    // console.log(this.sharedServiceInstance.user);
  }

  logout(): void {
    // this.authService.logout();
    this.authService.logout().subscribe( res => {
      if ( res ) {
        // console.log('success' + JSON.stringify(res));
        localStorage.clear();
        this.router.navigate(['/auth/login']);
      } else {
        // console.log('got an exception'  + JSON.stringify(res));
      } } , error => {
      // console.log('exce' + JSON.stringify(error));
    });
  }

}
